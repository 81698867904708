<template>
    <div class="merchant page-products page-products-manager">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- HEADER STORE -->
        <div class="load" v-if="loadMerchantStore"></div>
        <HeaderStore :dataSource="dataMerchantStore" v-if="!loadMerchantStore" />


        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-edit">
            <div class="container">
                <div class="load" v-if="load"></div>

                <div class="row" v-if="!load && errorsGet.dataProduct != false">
                    <div class="col-12">
                        <div class="box box-error">
                            <h3 class="title-secondary">
                                {{getMyLanguage("box-error","title-error")}}
                            </h3>

                            <p v-if='errorsGet.dataProduct != "401"'>
                                {{getMyLanguage("box-error","msg-error")}}
                            </p>

                            <p v-if='errorsGet.dataProduct == "401"'>
                                {{getMyLanguage("box-error","401")}}
                            </p>
                        </div>
                    </div>
                </div>


                <!-- STEPS -->
                <div class="row header-steps" :class="{loading: flowRegister.load}" v-if="!load && !errorsGet.dataProduct">
                    <div class="col-12">
                        <!-- <ul class="steps" :class="{right: flowRegister.step == 'stp03' || flowRegister.step == 'stp04'}"> -->
                        <ul class="steps">
                            <li class="step-01" :class='{active: flowRegister.step == "stp01", validated: flowRegister.step01Validated}' @click='navStep("stp01")'>
                                <strong>01</strong>
                                <small>Descrição</small>
                            </li>

                            <li class="step-02" :class='{active: flowRegister.step == "stp02", validated: flowRegister.step02Validated, disabled: !flowRegister.step01Validated}' @click='flowRegister.step01Validated ? navStep("stp02") : null'>
                                <strong>02</strong>
                                <small>Variantes</small>
                            </li>

                            <li class="step-03" :class='{active: flowRegister.step == "stp03", validated: flowRegister.step03Validated, disabled: !flowRegister.step01Validated}' @click='flowRegister.step01Validated ? navStep("stp03") : null'>
                                <strong>03</strong>
                                <small>Imagens</small>
                            </li>
                            
                            <!-- <li class="step-04" :class='{active: flowRegister.step == "stp04", validated: flowRegister.step04Validated, disabled: !flowRegister.step03Validated}' @click='navStep("stp04")'>
                                <strong>04</strong>
                                <small>Revisão</small>
                            </li> -->
                        </ul>
                    </div>
                </div>

                
                <!-- STEP 01 -->
                <div class="row step-01" v-if='flowRegister.step == "stp01" && !load && !errorsGet.dataProduct'>
                    <div class="load" v-if="flowRegister.load"></div>

                    <div class="col-12" v-if="!flowRegister.load">
                        <!-- <div class="select-type">
                            <label v-if='flowRegister.step == "stp01"'>Selecione o tipo</label>
                            
                            <ul v-if='flowRegister.step == "stp01"'>
                                <li>
                                    <div class="form-group-radio">
                                        <input type="radio" id="typeProduct" name="select-type" class="form-radio" value="product" :checked='selectedType == "product"'>
                                        <label for="typeProduct" class="form-check-label">Product</label>
                                    </div>
                                </li>

                                <li>
                                    <div class="form-group-radio">
                                        <input type="radio" id="typeService" name="select-type" class="form-radio" value="service" :checked='selectedType == "service"'>
                                        <label for="typeService" class="form-check-label">Service</label>
                                    </div>
                                </li>

                                <li>
                                    <div class="form-group-radio">
                                        <input type="radio" id="typeClassified" name="select-type" class="form-radio" value="classified" :checked='selectedType == "classified"'>
                                        <label for="typeClassified" class="form-check-label">Classified</label>
                                    </div>
                                </li>
                            </ul>
                        </div> -->

                        <h2 class="title-secondary" v-if="this.$route.params.productID != undefined">{{ dataProduct.name }}</h2>
                        <h2 class="title-secondary" v-else>Novo produto</h2>
                        
                        <div class="field">
                            <label>
                                <span class="info">
                                    * Nome do produto
                                    <i><span>Informe neste campo o nome simples do produto. Ex.: iPhone 16 Pro Max</span></i>
                                </span>

                                <span class="characters">{{dataProduct.name.length}} / 255</span>
                                <input type="text" maxlength="255"
                                    :class="['form', {error: flowRegister.validate.name === false}]"
                                    v-model="dataProduct.name"
                                    @keyup="generateSlugProduct(dataProduct.name, 'product'), removeError('product','name')">

                                <div class="product-slug" v-if="dataProduct.slug">
                                    <strong>URL: </strong>
                                    {{dataProduct.slug}}
                                </div>

                                <small class="obs">* Apenas o nome simples do produto, sem variantes, Ex.: iPhone 16 Pro Max</small>
                            </label>
                        </div>

                        <div class="field cols">
                            <label>
                                <span class="info">
                                    EAN
                                    <i><span>EAN (European Article Numbers) são sequências numéricas de 13 dígitos para identificação e controle dos produtos em todo o mundo.</span></i>
                                </span>
                                
                                <input type="text" class="form" v-model="dataProduct.ean" maxlength="50">
                            </label>
                            
                            <label>
                                <span class="info">
                                    SKU
                                    <i><span>SKU (Stock Keeping Unit) são códigos para identificação de produtos, usado para classificar (ex.: cores, tamanhos) e organizar os itens.</span></i>
                                </span>
                                
                                <input type="text" class="form" v-model="dataProduct.sku" maxlength="50">
                            </label>
                        </div>


                        <!-- CATEGORIES -->
                        <SetCategories @set-categories="handleSetCategories" v-if="productID == undefined" />


                        <!-- BRAND -->
                        <div class="filter">
                            <label>
                                <span class="info">
                                    * Marca
                                    <i><span>O produto deve ter obrigatoriamente uma marca para ajudar na organização e também nos filtros.</span></i>
                                </span>
                            </label>

                            <div class="selected-option" v-if="dataProduct.brand_id && !load_step01">
                                <strong>
                                    {{dataBrands.filter(brand => brand.id == dataProduct.brand_id)[0].name}}
                                </strong>
                            </div>

                            <input type="search"
                                inputmode="search"
                                :class="['form form-search-icon', {error: flowRegister.validate.brand_id === false}]"
                                placeholder="Buscar marca"
                                v-if="!flowBrand.add"
                                v-model="flowBrand.search"
                                @keyup="removeError('product','brand_id')"
                                @input="filterBrands()"/>

                            <div class="open-flow-add"
                                v-if="!flowBrand.add && flowBrand.search && !flowBrand.results.length"
                                @click="flowBrand.add = true">

                                <strong>ADD&nbsp;>>&nbsp;</strong>
                                <small>{{flowBrand.search}}</small>
                            </div>

                            <div class="search-list" id="list-brand" v-if="flowBrand.results.length && !flowBrand.add">
                                <span v-for="(brand, index) in flowBrand.results.slice(0, 10)" :key="index" @click="selectBrand('product', brand)">
                                    {{brand.name}}
                                </span>
                            </div>

                            <div class="flow-add flow-add-brand" v-if="flowBrand.add">
                                <h3 class="title-secondary">Nova marca</h3>
                                <p>Informe corretamente o nome da marca.</p>
                                <input type="text" class="form add" placeholder="Nova marca" v-model="flowBrand.search">

                                <div class="bts">
                                    <div class="btn-cancel" @click="flowBrand.add = false">Cancelar</div>
                                    <div class="btn-primary" @click="addBrand('product')">Salvar</div>
                                </div>
                            </div>
                        </div>


                        <!-- CATEGORY -->
                        <div class="filter">
                            <label>
                                <span class="info">
                                    * Categoria
                                    <i><span>abcde</span></i>
                                </span>
                            </label>

                            <div class="selected-option" v-if="dataProduct.category_id">
                                <strong>
                                    {{ categoryName(dataCategories, dataProduct.category_id)?.name || 'Selecione uma categoria' }}
                                </strong>
                            </div>

                            <input type="search"
                                inputmode="search"
                                :class="['form form-search-icon', {error: flowRegister.validate.category_id === false}]"
                                placeholder="Buscar categorias"
                                v-if="!flowCategory.add.show"
                                v-model="flowCategory.search"
                                @keyup="removeError('product','category_id')"
                                @input="filterCategories()"/>
                            
                            <div class="open-flow-add"
                                v-if="!flowCategory.add.show && flowCategory.search && !flowCategory.results.length"
                                @click="flowCategory.add.show = true, sortCategories()">

                                <strong>ADD&nbsp;>>&nbsp;</strong>
                                <small>{{flowCategory.search}}</small>
                            </div>

                            <div class="scroll" v-if="!flowCategory.add.show && flowCategory.results.length">
                                <ul class="list">
                                    <li v-for="(category, index) in flowCategory.results" :key="index">
                                        <div class="form-group-radio">
                                            <input type="radio" :id="'categ' + category.id" name="select-category" class="form-radio"
                                                :value="category.name"
                                                @change="selectCategory('product', category.id, category.name)" />
                                            <label :for="'categ' + category.id" class="form-check-label">{{ category.name }}</label>
                                        </div>

                                        <ul v-if="category.children && category.children.length" class="sublist" :class="{hasChildren: category.children.length > 0}">
                                            <li v-for="(subcategory, subIndex) in category.children" :key="subIndex">
                                                <div class="form-group-radio">
                                                    <input type="radio" :id="'subcateg' + subcategory.id" name="select-category" class="form-radio"
                                                        :value="subcategory.name"
                                                        @change="selectCategory('product', subcategory.id, subcategory.name)" />
                                                    <label :for="'subcateg' + subcategory.id" class="form-check-label">{{ subcategory.name }}</label>
                                                </div>

                                                <ul v-if="subcategory.children && subcategory.children.length" class="sub-sublist" :class="{hasChildren: subcategory.children.length > 0}">
                                                    <li v-for="(subSubcategory, subSubIndex) in subcategory.children" :key="subSubIndex">
                                                        <div class="form-group-radio">
                                                            <input type="radio" :id="'subsubcateg' + subSubcategory.id" name="select-category"
                                                                class="form-radio" :value="subSubcategory.name"
                                                                @change="selectCategory('product', subSubcategory.id, subSubcategory.name)" />
                                                            <label :for="'subsubcateg' + subSubcategory.id" class="form-check-label">{{ subSubcategory.name }}</label>
                                                        </div>

                                                        <ul v-if="subSubcategory.children && subSubcategory.children.length" class="sub-sub-sublist" :class="{hasChildren: subSubcategory.children.length > 0}">
                                                            <li v-for="(subSubSubcategory, subSubSubIndex) in subSubcategory.children" :key="subSubSubIndex">
                                                                <div class="form-group-radio">
                                                                    <input type="radio" :id="'subsubsubcateg' + subSubSubcategory.id"
                                                                        name="select-category" class="form-radio" :value="subSubSubcategory.name"
                                                                        @change="selectCategory('product', subSubSubcategory.id, subSubSubcategory.name)" />
                                                                    <label :for="'subsubsubcateg' + subSubSubcategory.id"
                                                                        class="form-check-label">{{ subSubSubcategory.name }}</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            
                            <div class="flow-add flow-add-category" v-if="flowCategory.add.show">
                                <h3 class="title-secondary">Nova categoria</h3>
                                <p>Informe corretamente o nome da categoria.</p>

                                <div class="select-add">
                                    <select v-model="flowCategory.add.selectedCategory" class="form">
                                        <optgroup>
                                            <option :value="null" disabled>Selecione ou crie uma nova categoria pai</option>
                                            <option :value="'00000000-0000-0000-0000-000000000000'">Criar como categoria principal</option>
                                        </optgroup>

                                        <optgroup label="Categorias">
                                            <option
                                                v-for="category in flowCategory.add.sortedCategories"
                                                :key="category.id"
                                                :value="category.id">

                                                {{ category.fullPath }}
                                            </option>
                                        </optgroup>
                                    </select>
                                    
                                    <input type="text" class="form add" placeholder="Nova categoria" v-model="flowCategory.search">
                                </div>

                                <div class="bts">
                                    <div class="btn-cancel" @click="flowCategory.add.show = false">Cancelar</div>

                                    <div class="btn-primary disabled" v-if="!flowCategory.add.selectedCategory || !flowCategory.search">Salvar</div>
                                    <div class="btn-primary" v-if="flowCategory.add.selectedCategory && flowCategory.search" @click="addCategory()">Salvar</div>
                                </div>
                            </div>
                        </div>
                        

                        <!-- DESCRIPTION -->
                        <div class="field">
                            <label>
                                <span class="info">
                                    * Descrição completa
                                    <i><span>Descrição completa. *Exceto informações ténicas que serão informadas nos proximos campos.</span></i>
                                </span>
                            </label>

                            <div :class="['editor-html', {error: flowRegister.validate.description === false}]">
                                <quill-editor
                                    v-model:content="dataProduct.description"
                                    contentType="html"
                                    theme="snow"
                                    :options="editorOptions" />
                            </div>

                            <!-- <textarea
                                :class="['form', {error: flowRegister.validate.description === false}]"
                                @keyup="removeError('product','description')"
                                v-model="dataProduct.description">
                            </textarea> -->

                            <small class="obs">* Para facilitar a venda, recomendamos uma descrição com qualidade.</small>
                        </div>
                        
                        <div class="bts alert"
                            v-if="!dataProduct.variants.variant_1.variant_type_id ||
                                !dataProduct.variants.variant_1.variant_type ||
                                !dataProduct.variants.variant_1.variant_id ||
                                !dataProduct.variants.variant_1.variant ||
                                !dataProduct.variants.variant_2.variant_type_id ||
                                !dataProduct.variants.variant_2.variant_type ||
                                !dataProduct.variants.variant_2.variant_id ||
                                !dataProduct.variants.variant_2.variant">
                            
                            <p>Tipos de variantes ainda estão pendentes</p>

                            <div class="btn-primary btn-disabled">Salvar e continuar</div>
                        </div>

                        <div class="bts center" v-else>
                            <div class="btn-primary btn-disabled" v-if="productID">
                                Salvar e continuar
                            </div>
                            
                            <div class="btn-primary" v-else @click='save("stp01")'>
                                Salvar e continuar
                            </div>
                        </div>

                        <!-- <pre>{{dataProduct}}</pre> -->
                    </div>
                </div>
                


                <!-- STEP 02 -->
                <div class="row step-02" v-if='flowRegister.step == "stp02" && !load'>
                    <div class="load" v-if="flowRegister.load"></div>

                    <div class="col-12" v-if="!flowRegister.load">
                        <h2 class="title-secondary">Variantes</h2>

                        <div class="bts center" v-if="dataVariants.length == 1 && dataVariants[0].variant_type_id_1 == '00000000-0000-0000-0000-000000000000' && dataVariants[0].variant_type_id_2 == '00000000-0000-0000-0000-000000000000'">
                            <div class="btn-primary add-variant" @click='flowAddVariant.modal = true'>
                                Adicionar variante
                            </div>
                        </div>

                        <div class="instructions" v-else>
                            <p v-if="dataVariants.length === 1 && dataVariants[0].status_extra === 'draft'">
                                * Preencha os dados desta variante corretamente para utilizá-los em futuras variantes.
                            </p>

                            <p v-else-if="dataVariants.some(variant => variant.status_extra !== 'draft')">
                                * Duplique variante para otimizar o preenchimento das informações e economizar tempo.
                            </p>
                        </div>
                        
                        <div class="variants">
                            <div
                                :class="['variant', variant.status_extra]"
                                v-for="(variant, indexvariant) in dataVariants"
                                :key="indexvariant">

                                    <!-- <div class="photo">
                                        <img src="">
                                    </div> -->

                                    <div class="cont-variant" @click="openModalVariant(variant)">
                                        <h3>{{ variant.name }}</h3>

                                        <div class="resume">
                                            <span>Status <strong>{{ variant.status_extra }}</strong></span>
                                            <span>{{ variant.variant_type_1 }}<strong>{{ variant.variant_1 }}</strong></span>
                                            <span>{{ variant.variant_type_2 }}<strong>{{ variant.variant_2 }}</strong></span>
                                            <span>Categoria <strong>{{ variant.category }}</strong></span>
                                            <span>Estoque <strong>{{ variant.quantity_available }}</strong></span>
                                            <!-- <span>Preço <strong>{{ (variant.price = this.formatPriceFromApi(variant.price)).toLocaleString(isoAlpha3, {style:"currency", currency:dataProduct.instrument.quote_asset.short}) }}</strong></span> -->
                                            <span>Preço <strong>{{ (variant.price = this.formatPriceFromApi(variant.price)) }}</strong></span>
                                            <span>
                                                Entrega
                                                <img src="~@/assets/images/icons/checked.svg" v-if="variant.shipping.shippable">
                                                <img src="~@/assets/images/icons/unchecked.svg" v-else>
                                            </span>
                                        </div>
                                    </div>

                                <!-- <div class="btn-clone" @click="cloneVariant(variant.id)" v-if="variant.status_extra != 'draft' && variant.status_extra != 'DefaultAttributeValue'">
                                    <span>
                                        Duplicar variante
                                    </span>
                                </div> -->

                                <div class="btn-clone" @click="cloneVariant(variant.id)">
                                    <span>
                                        Duplicar variante
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-12 bts" v-if="!flowRegister.load">
                        <div class="btn-back" @click='flowRegister.step = "stp02", goTop()'></div>

                        <div class="btn-primary btn-disabled" v-if="dataVariants == 0">
                            Salvar e continuar
                        </div>

                        <div class="btn-primary" @click='save("stp02")' v-if="dataVariants != 0">
                            Salvar e continuar
                        </div>
                    </div> -->
                </div>

                

                <!-- STEP 03 -->
                <div class="row step-03" v-if='flowRegister.step == "stp03" && !load'>
                    <div class="load" v-if="flowRegister.load"></div>

                    <div class="col-12" v-if="!flowRegister.load">
                        <!-- <h2 class="title-secondary">{{dataProduct.name}}</h2> -->
                        <h2 class="title-secondary">Imagens</h2>

                        <MultipleUpload :variants="dataVariants" />

                        <!-- <div class="bts">
                            <div class="btn-back" @click='flowRegister.step = "stp01", goTop()'></div>
                            <div class="btn-primary" @click='save("stp02")'>Salvar e continuar</div>
                        </div> -->
                    </div>
                </div>


                <!-- STEP 04 -->
                <div class="row step-04" v-if='flowRegister.step == "stp04" && !load'>
                    <div class="col-12">
                        PREVIEW
                    </div>

                    <div class="col-12 bts">
                        <div class="btn-back" @click='flowRegister.step = "stp03", goTop()'></div>

                        <div class="btn-primary" @click='save("stp04")'>
                            Publicar
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- MODAL ADD VARIABLE -->
        <div class="modal modal-add-variable" v-if="flowAddVariant.modal">
            <div class="modal-cont">
                <div class="modal-close" @click="flowAddVariant.modal = false, flowAddVariant.cloneId = ''"></div>

                <div class="modal-scroll">
                    <AddVariant
                        :nameProduct="dataProduct.name"
                        :idProduct="dataProduct.id"
                        :page="page"
                        :cloneId="flowAddVariant.cloneId"
                        :blockVariables="flowAddVariant.variables"
                        @variantAdded="addedArrayVariant"
                        @closeAdd="closeAddVariant" />
                </div>
            </div>
        </div>


        <!-- MODAL EDT VARIABLE -->
        <div class="modal modal-edt-variable" v-if="modalVariant.modal">
            <div class="modal-cont">
                <div class="modal-close" @click="modalVariant.modal = false, flowAddVariant.cloneId = ''"></div>

                <div class="load" v-if="modalVariant.load"></div>

                <div class="modal-scroll" v-if="!modalVariant.load">
                    <h2 class="title-secondary">{{modalVariant.data.name}}</h2>

                    <div class="field">
                        <label>
                            <span class="info">
                                * Nome completo do produto
                                <i><span>Informe o nome completo do produto. Ex.: Nome do produto + Variantes + Informações Adicionais como marca, sku, etc...</span></i>
                            </span>

                            <span class="characters">{{modalVariant.data.name.length}} / 255</span>
                            <input type="text" maxlength="255"
                                :class="['form', {error: flowRegisterVariant.validate.name === false}]"
                                v-model="modalVariant.data.name"
                                @keyup="generateSlugProduct(modalVariant.data.name, 'variant'), removeError('variant','name')">
                            
                            <div class="product-slug" v-if="modalVariant.data.slug">
                                <strong>URL:</strong>
                                {{modalVariant.data.slug}}
                            </div>

                            <small class="obs">* Ex.: Nome do produto + Variantes + Informações Adicionais</small>
                        </label>
                    </div>

                    <div class="field">
                        <label>
                            <span class="info">
                                Palavras substitutas
                                <i><span>São variações do nome do produto que possivelmente o usuário possa pesquisar. Ex.: portátil, notebook, desktop</span></i>
                            </span>
                            <small>(que tenham a mesma semântica)</small>

                            <vue3-tags-input
                                class="tags-input"
                                :tags="modalVariant.data.semantics"
                                placeholder='Digite e pressione "Enter"'
                                @on-tags-changed="handleChangeTag"/>
                        </label>

                        <small class="example">Geladeira > Frigorífico</small>
                        <small class="example">Calvin Klein > Calvin Klain</small>
                        <small>&nbsp;</small>
                        <small class="obs">* Essas palavras serão utilizadas nas buscas internas e em sites de busca.</small>
                    </div>


                    <!-- STATUS -->
                    <div class="field">
                        <label>
                            <span class="info">
                                * Status
                                <i><span>Situação atual deste produto.</span></i>
                            </span>

                            <select
                                :class="['form', {error: flowRegisterVariant.validate.status_id === false}]"
                                @change="checkStatusVariant(), removeError('variant','status_id')"
                                v-model="modalVariant.data.status_id">

                                <option v-for="status in modalVariant.status" :key="status.Id" :value="status.Id">
                                    {{status.Value}}
                                </option>
                            </select>
                        </label>
                    </div>


                    <!-- PRICE -->
                    <div class="field">
                        <label>
                            <span class="info">
                                * Preço do produto / variante
                                <i><span>Valor correspondente a sua moeda corrente.</span></i>
                            </span>
                        </label>

                        <div class="currency-price">
                            <div>
                                <label>* Sua moeda corrente</label>
                                <select
                                    :class="['form', {error: flowRegisterVariant.validate.asset_id === false}]"
                                    @keyup="removeError('variant','asset_id')"
                                    v-model="modalVariant.data.asset_id">

                                    <option
                                        v-for="currency in modalVariant.currency"
                                        :key="currency.id"
                                        :value="currency.id">

                                        {{ currency.description }}
                                    </option>
                                </select>
                            </div>
                            
                            <div class="currency">
                                <i>{{modalVariant.currency.filter(asset => asset.id == modalVariant.data.asset_id)[0].short}}</i>
                                
                                <label>
                                    Preço
                                    <div>
                                        <input type="text"
                                            :class="['form', {error: flowRegisterVariant.validate.price === false}]"
                                            @keyup="removeError('variant','price')"
                                            @input="applyMask"
                                            :value="modalVariant.data.price" />
                                        
                                        <span>{{modalVariant.currency.filter(asset => asset.id == modalVariant.data.asset_id)[0].symbol}}</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        
                        <small class="obs">* Utilize sua moeda corrente em todas as variantes.</small>
                    </div>


                    <div class="field quantities">
                        <label>
                            <span class="info">
                            Estoque disponível
                            <i><span>Informe a quantidade disponível para venda imediata.</span></i>
                            </span>
                            
                            <input 
                                type="number" 
                                :class="['form', {error: flowRegisterVariant.validate.quantity_available === false}]" 
                                @input="validateQuantity('quantity_available')" 
                                @keyup="removeError('variant','quantity_available')" 
                                v-model="modalVariant.data.quantity_available">
                        </label>
                        
                        <label>
                            <span class="info">
                            Quantidade máxima
                            <i><span>Informe a quantidade máxima para uma única venda.</span></i>
                            </span>

                            <input 
                                type="number" 
                                :class="['form', {error: flowRegisterVariant.validate.quantity_maximum === false}]" 
                                @input="validateQuantity('quantity_maximum')" 
                                @keyup="removeError('variant','quantity_maximum')" 
                                v-model="modalVariant.data.quantity_maximum">
                        </label>

                        <label>
                            <span class="info">
                                Quantidade mínima
                                <i><span>Informe a quantidade mínima para venda.</span></i>
                            </span>

                            <input 
                                type="number" 
                                :class="['form', {error: flowRegisterVariant.validate.quantity_minimum === false}]" 
                                @input="validateQuantity('quantity_minimum')" 
                                @keyup="removeError('variant','quantity_minimum')" 
                                v-model="modalVariant.data.quantity_minimum">
                        </label>
                    </div>

                    
                    <div class="field cols">
                        <label>
                            <span class="info">
                                EAN
                                <i><span>EAN (European Article Numbers) são sequências numéricas de 13 dígitos para identificação e controle dos produtos em todo o mundo.</span></i>
                            </span>
                            
                            <input type="text" class="form" v-model="modalVariant.data.ean" maxlength="50">

                            <small class="obs">Aplicável se for diferente para cada variante.</small>
                        </label>
                        
                        <label>
                            <span class="info">
                                SKU
                                <i><span>SKU (Stock Keeping Unit) são códigos para identificação de produtos, usado para classificar (ex.: cores, tamanhos) e organizar os itens.</span></i>
                            </span>
                            
                            <input type="text" class="form" v-model="modalVariant.data.sku" maxlength="50">

                            <small class="obs">Aplicável se for diferente para cada variante.</small>
                        </label>
                    </div>


                    <!-- BRAND -->
                    <div class="filter">
                        <label>
                            <span class="info">
                                * Marca
                                <i><span>A variante deve ter uma marca para ajudar na organização e também nos filtros.</span></i>
                            </span>
                        </label>

                        <div class="selected-option" v-if="modalVariant.data.brand_id  && !load_step01">
                            <strong>
                                {{dataBrands.filter(brand => brand.id == modalVariant.data.brand_id )[0].name}}
                            </strong>
                        </div>

                        <input type="search"
                            inputmode="search"
                            :class="['form form-search-icon', {error: flowRegisterVariant.validate.brand_id === false}]"
                            placeholder="Buscar marca"
                            v-if="!flowBrand.add"
                            v-model="flowBrand.search"
                            @keyup="removeError('variant','brand_id')"
                            @input="filterBrands()"/>

                        <div class="open-flow-add"
                            v-if="!flowBrand.add && flowBrand.search && !flowBrand.results.length"
                            @click="flowBrand.add = true">

                            <strong>ADD&nbsp;>>&nbsp;</strong>
                            <small>{{flowBrand.search}}</small>
                        </div>

                        <div class="search-list" id="list-brand" v-if="flowBrand.results.length && !flowBrand.add">
                            <span v-for="(brand, index) in flowBrand.results.slice(0, 10)" :key="index" @click="selectBrand('variant',brand)">
                                {{brand.name}}
                            </span>
                        </div>

                        <div class="flow-add flow-add-brand" v-if="flowBrand.add">
                            <h3 class="title-secondary">Nova marca</h3>
                            <p>Informe corretamente o nome da marca.</p>
                            <input type="text" class="form add" placeholder="Nova marca" v-model="flowBrand.search">

                            <div class="bts">
                                <div class="btn-cancel" @click="flowBrand.add = false">Cancelar</div>
                                <div class="btn-primary" @click="addBrand('variant')">Salvar</div>
                            </div>
                        </div>
                    </div>


                    <!-- CATEGORY -->
                    <div class="filter">
                        <label>
                            <span class="info">
                                * Categoria
                                <i><span>abcde</span></i>
                            </span>
                        </label>

                        <div class="selected-option" v-if="modalVariant.data.category_id">
                            <strong>
                                {{ categoryName(dataCategories, modalVariant.data.category_id)?.name || 'Selecione uma categoria' }}
                            </strong>
                        </div>

                        <input type="search"
                            inputmode="search"
                            :class="['form form-search-icon', {error: flowRegister.validate.category_id === false}]"
                            placeholder="Buscar categorias"
                            v-if="!flowCategory.add.show"
                            v-model="flowCategory.search"
                            @keyup="removeError('product','category_id')"
                            @input="filterCategories()"/>
                        
                        <div class="open-flow-add"
                            v-if="!flowCategory.add.show && flowCategory.search && !flowCategory.results.length"
                            @click="flowCategory.add.show = true, sortCategories()">

                            <strong>ADD&nbsp;>>&nbsp;</strong>
                            <small>{{flowCategory.search}}</small>
                        </div>

                        <div class="scroll" v-if="!flowCategory.add.show && flowCategory.results.length">
                            <ul class="list">
                                <li v-for="(category, index) in flowCategory.results" :key="index">
                                    <div class="form-group-radio">
                                        <input type="radio" :id="'categ' + category.id" name="select-category" class="form-radio"
                                            :value="category.name"
                                            @change="selectCategory('variant', category.id, category.name)" />
                                        <label :for="'categ' + category.id" class="form-check-label">{{ category.name }}</label>
                                    </div>

                                    <ul v-if="category.children && category.children.length" class="sublist" :class="{hasChildren: category.children.length > 0}">
                                        <li v-for="(subcategory, subIndex) in category.children" :key="subIndex">
                                            <div class="form-group-radio">
                                                <input type="radio" :id="'subcateg' + subcategory.id" name="select-category" class="form-radio"
                                                    :value="subcategory.name"
                                                    @change="selectCategory('variant', subcategory.id, subcategory.name)" />
                                                <label :for="'subcateg' + subcategory.id" class="form-check-label">{{ subcategory.name }}</label>
                                            </div>

                                            <ul v-if="subcategory.children && subcategory.children.length" class="sub-sublist" :class="{hasChildren: subcategory.children.length > 0}">
                                                <li v-for="(subSubcategory, subSubIndex) in subcategory.children" :key="subSubIndex">
                                                    <div class="form-group-radio">
                                                        <input type="radio" :id="'subsubcateg' + subSubcategory.id" name="select-category"
                                                            class="form-radio" :value="subSubcategory.name"
                                                            @change="selectCategory('variant', subSubcategory.id, subSubcategory.name)" />
                                                        <label :for="'subsubcateg' + subSubcategory.id" class="form-check-label">{{ subSubcategory.name }}</label>
                                                    </div>

                                                    <ul v-if="subSubcategory.children && subSubcategory.children.length" class="sub-sub-sublist" :class="{hasChildren: subSubcategory.children.length > 0}">
                                                        <li v-for="(subSubSubcategory, subSubSubIndex) in subSubcategory.children" :key="subSubSubIndex">
                                                            <div class="form-group-radio">
                                                                <input type="radio" :id="'subsubsubcateg' + subSubSubcategory.id"
                                                                    name="select-category" class="form-radio" :value="subSubSubcategory.name"
                                                                    @change="selectCategory('variant', subSubSubcategory.id, subSubSubcategory.name)" />
                                                                <label :for="'subsubsubcateg' + subSubSubcategory.id"
                                                                    class="form-check-label">{{ subSubSubcategory.name }}</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        
                        <div class="flow-add flow-add-category" v-if="flowCategory.add.show">
                            <h3 class="title-secondary">Nova categoria</h3>
                            <p>Informe corretamente o nome da categoria.</p>

                            <div class="select-add">
                                <select v-model="flowCategory.add.selectedCategory" class="form">
                                    <optgroup>
                                        <option :value="null" disabled>Selecione ou crie uma nova categoria pai</option>
                                        <option :value="'00000000-0000-0000-0000-000000000000'">Criar como categoria principal</option>
                                    </optgroup>

                                    <optgroup label="Categorias">
                                        <option v-for="category in flowCategory.add.sortedCategories" :key="category.id" :value="category.id">
                                            {{ category.fullPath }}
                                        </option>
                                    </optgroup>
                                </select>
                                
                                <input type="text" class="form add" placeholder="Nova categoria" v-model="flowCategory.search">
                            </div>

                            <div class="bts">
                                <div class="btn-cancel" @click="flowCategory.add.show = false">Cancelar</div>

                                <div class="btn-primary disabled" v-if="!flowCategory.add.selectedCategory || !flowCategory.search">Salvar</div>
                                <div class="btn-primary" v-if="flowCategory.add.selectedCategory && flowCategory.search" @click="addCategory()">Salvar</div>
                            </div>
                        </div>
                    </div>
                    

                    <!-- DESCRIPTIONS -->
                    <div class="field">
                        <label>
                            <span class="info">
                                * Descrição curta
                                <i><span>Um pequeno resumo do produto, destacando as principais características.</span></i>
                            </span>
                            
                            <span class="characters" :class="{unlimited: modalVariant.data.short_description.length < 50}">{{modalVariant.data.short_description.length}} / 160</span>
                            <textarea
                                :class="['form short', {error: flowRegisterVariant.validate.short_description === false}]"
                                @keyup="removeError('variant','short_description')"
                                v-model="modalVariant.data.short_description"
                                maxlength="160">
                            </textarea>
                        </label>

                        <small class="obs">* Recomendado entre 50 a 160 caracteres.</small>
                    </div>


                    <!-- DESCRIPTION FULL -->
                    <div class="field">
                        <label>
                            <span class="info">
                                * Descrição completa
                                <i><span>Descrição completa. *Exceto informações ténicas que serão informadas nos proximos campos.</span></i>
                            </span>
                        </label>

                        <div :class="['editor-html', {error: flowRegisterVariant.validate.description === false}]">
                            <quill-editor
                                v-model:content="modalVariant.data.description"
                                contentType="html"
                                theme="snow"
                                :options="editorOptions" />
                        </div>

                        <small class="obs">* Para facilitar a venda, recomendamos uma descrição com qualidade.</small>
                    </div>

                    <div class="field">
                        <label>
                            <span class="info">
                                Descrição técnica
                                <i><span>abcde</span></i>
                            </span>
                        </label>

                        <div class="field-value">
                            <input type="text" class="form" placeholder="Tag" v-model="flowRegisterVariant.attributes.Key">
                            <span>>></span>
                            <input type="text" class="form add" placeholder="Descrição" v-model="flowRegisterVariant.attributes.Value">

                            <div class="bt-add disabled" v-if="!flowRegisterVariant.attributes.Key || !flowRegisterVariant.attributes.Value"></div>
                            <div class="bt-add" v-if="flowRegisterVariant.attributes.Key && flowRegisterVariant.attributes.Value" @click="addTags()"></div>
                        </div>

                        <div class="list-field">
                            <div class="list" v-for="(attr, indexAttr) in modalVariant.data.attributes" :key="indexAttr">
                                <span class="remove" @click="removeTag(indexAttr)"></span>
                                <div>
                                    <input type="text" class="formList" v-model="attr.Key">
                                    <input type="text" class="formList" v-model="attr.Value">
                                </div>
                            </div>
                        </div>

                        <small class="example">Cor >> Amarelo</small>
                        <small class="example">Largura >> 100cm</small>
                        <small>&nbsp;</small>
                        <small class="obs">* Informações técnicas também ajudam na venda.</small>
                    </div>


                    <!-- SHIPPING -->
                    <div class="field">
                        <label class="hasCheck">
                            <span class="info">
                                Pesos e medidas
                                <i><span>abcde</span></i>
                            </span>

                            <small class="obs">* Indispensável para o cálculo de entrega.</small>

                            <div class="form-group-check">
                                <input
                                    type="checkbox"
                                    class="form-check"
                                    id="measurementsCheck"
                                    @click="modalVariant.data.shipping.shippable = !modalVariant.data.shipping.shippable"
                                    :checked="modalVariant.data.shipping.shippable" />

                                <label for="measurementsCheck" class="form-check-label">
                                    <span>Aplicar</span>
                                </label>
                            </div>
                        </label>

                        <div class="shipping" v-if="modalVariant.data.shipping.shippable">
                            <div class="measurement-package">
                                <div class="units">
                                    <label>* Selecione o sistema de medida:</label>
                                    <select
                                        :class="['form', {error: flowRegisterVariant.validateShipping.shipping_measure_unit_id === false}]"
                                        v-model="modalVariant.data.shipping.shipping_measure_unit_id"
                                        @change="changeMeasureUnit, removeError('variant','shipping_measure_unit_id','shipping')">

                                        <option
                                            v-for="measure in dataMeasureUnit"
                                            :key="measure.Id"
                                            :value="measure.Id">

                                            {{ measure.Value }}
                                        </option>
                                    </select>
                                </div>
                                
                                <div class="package" v-if="modalVariant.data.shipping.shipping_measure_unit_id && modalVariant.data.shipping.shipping_measure_unit_id !='00000000-0000-0000-0000-000000000000'">
                                    <label>Selecione uma embalagem:</label>
                                    <select
                                        class="form"
                                        v-model="modalVariant.data.shipping.shipping_packaging_id"
                                        @change="selectPackage">
                                        
                                        <option v-for="pack in dataPackages" :key="pack.id" :value="pack.id">
                                            {{ pack.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="measurements" v-if="modalVariant.data.shipping.shipping_measure_unit_id && modalVariant.data.shipping.shipping_measure_unit_id !='00000000-0000-0000-0000-000000000000'">
                                <div>
                                    <div class="measure">
                                        <label for="length">* Comprimento <small>({{ unit }})</small></label>
                                        <div class="form form-disabled" v-if="modalVariant.data.shipping.shipping_packaging_id !== '00000000-0000-0000-0000-000000000000'">{{ modalVariant.data.shipping.length }}</div>
                                        <input type="number"
                                            :class="['form', {error: flowRegisterVariant.validateShipping.length === false}]"
                                            @keyup="removeError('variant','length','shipping')"
                                            v-else
                                            v-model="modalVariant.data.shipping.length" />
                                    </div>

                                    <div class="measure">
                                        <label for="width">* Largura <small>({{ unit }})</small></label>
                                        <div class="form form-disabled" v-if="modalVariant.data.shipping.shipping_packaging_id !== '00000000-0000-0000-0000-000000000000'">{{ modalVariant.data.shipping.width }}</div>
                                        <input type="number"
                                            :class="['form', {error: flowRegisterVariant.validateShipping.width === false}]"
                                            @keyup="removeError('variant','width','shipping')"
                                            v-else
                                            v-model="modalVariant.data.shipping.width" />
                                    </div>

                                    <div class="measure">
                                        <label for="height">* Altura <small>({{ unit }})</small></label>
                                        <div class="form form-disabled" v-if="modalVariant.data.shipping.shipping_packaging_id !== '00000000-0000-0000-0000-000000000000'">{{ modalVariant.data.shipping.height }}</div>
                                        <input type="number"
                                            :class="['form', {error: flowRegisterVariant.validateShipping.height === false}]"
                                            @keyup="removeError('variant','height','shipping')"
                                            v-else
                                            v-model="modalVariant.data.shipping.height" />
                                    </div>

                                    <div class="measure">
                                        <label for="weight">* Peso <small>({{ weightUnit }})</small></label>
                                        <input type="number"
                                            :class="['form', {error: flowRegisterVariant.validateShipping.weight === false}]"
                                            @keyup="removeError('variant','weight','shipping')"
                                            v-model="modalVariant.data.shipping.weight"
                                            id="measure_weight" />
                                    </div>
                                </div>

                                <div v-if="modalVariant.data.shipping.cubing && modalVariant.data.shipping.weight" class="cubage">
                                    <div class="resume">
                                        <p>Volume: <strong>{{ modalVariant.data.shipping.cubing }}</strong>&nbsp;{{ volumeUnit }}</p>
                                        <p>Peso total: <strong>{{ modalVariant.data.shipping.weight }}</strong>&nbsp;{{ weightUnit }}</p>
                                    </div>
                                </div>
                                
                                <small class="obs">* Recomendamos muita atenção nesses campos.</small>
                            </div>


                            <div class="warehouse" v-if="modalVariant.data.shipping.shipping_measure_unit_id && modalVariant.data.shipping.shipping_measure_unit_id !='00000000-0000-0000-0000-000000000000'">
                                <label for="measure-system" v-if="modalVariant.warehouses.length">Selecione o armazém/depósito desta variante:</label>

                                <div class="alert"  v-if="!modalVariant.warehouses.length">
                                    <p>Nenhum armazém/depósito encontrado</p>
                                    <a :href="`/${flagSelected}/merchant/store`" class="btn-primary">Corrigir agora</a>
                                </div>

                                <select
                                    v-if="modalVariant.warehouses.length"
                                    v-model="modalVariant.data.warehouse_id"
                                    class="form">

                                    <option
                                        v-for="warehouse in modalVariant.warehouses"
                                        :key="warehouse.id"
                                        :value="warehouse.id">
                                        
                                        {{warehouse.formatted_address}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- <pre>{{modalVariant.data.shipping}}</pre> -->

                    <div class="bts center">
                        <div class="btn-primary" @click="saveVariant()">
                            Salvar
                        </div>
                    </div>
                </div>
            </div>
        </div>





        <!-- MODAL ADD/EDT VARIABLE -->
        <!-- <div class="modal modal-variables" :class="{noGallery: !modal.managerGallery}" v-if="modal.variables"> -->
        <div class="modal modal-variables" v-if="modal.variables">
            <div class="modal-cont" :class="{modalShort: del}">
                <div class="modal-close" @click="modal.variables = false"></div>

                <div class="modal-scroll">

                    <!-- ADD -->
                    <div class="add" v-if='modal.variablesAction == "add"'>
                        <h3 class="title-secondary">
                            Add variante
                        </h3>

                        <div class="field">
                            <label>
                                <span class="info">
                                    Nome da variante
                                    <i><span>abcde</span></i>
                                </span>

                                <input type="text" class="form" v-model="flowRegister.variable[0]">

                                <p class="preview-name">{{dataProduct.name}} <span>{{flowRegister.variable[0]}}</span></p>
                            </label>

                            <label>
                                <span class="info">
                                    Valor da variante
                                    <i><span>abcde</span></i>
                                </span>

                                <input type="number" inputmode="decimal" class="form form-price" v-model="flowRegister.variable[1]" placeholder="0">
                            </label>

                            <div class="btn-primary" @click="addVariant()">Add</div>
                        </div>
                    </div>


                    <!-- EDT -->
                    <div class="edt" v-if='modal.variablesAction == "edt"'>
                        <h3 class="title-secondary">Editar variante</h3>
                        
                        <div class="field">
                            <label>
                                <span class="info">
                                    Nome da variante
                                    <i><span>abcde</span></i>
                                </span>
                                
                                <input type="text" class="form" value="variante do v-model" placeholder="Nome da variante">

                                <p class="preview-name">{{dataProduct.name}} <span>{{}}</span></p>
                            </label>

                            <label>
                                <span class="info">
                                    Valor da variante
                                    <i><span>abcde</span></i>
                                </span>

                                <input type="number" inputmode="decimal" class="form form-price" placeholder="0">
                            </label>

                            <div class="btn-primary">Salvar</div>
                        </div>
                    </div>


                    <!-- DEL -->
                    <div class="del" v-if='modal.variablesAction == "del"'>
                        <h3 class="title-secondary">Remover variante</h3>

                        <div class="del-cont">
                            <h3>{{dataProduct.name}}</h3>
                            <h2>Nome da variável</h2>
                            <div class="btn-cancel">Excluir</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from "@/components/Essentials/Header.vue";
    import HeaderStore from "@/components/Merchant/HeaderStore.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";

    import { dataMixin } from './dataMixin.js';
    import customScriptGeneral from "./script-product-general.js";
    import customScriptsStep01 from "./script-product-step01.js";
    import customScriptsStep02 from "./script-product-step02.js";
    import customScriptsStep03 from "./script-product-step03.js";

    import Vue3TagsInput from 'vue3-tags-input';

    export default {
        components: {
            Header,
            HeaderStore,
            Breadcrumb,
            Vue3TagsInput
        },

        mixins: [
            dataMixin,
            customScriptGeneral,
            customScriptsStep01,
            customScriptsStep02,
            customScriptsStep03
        ],
    }
</script>

<style src="./custom-product-manager.scss" lang="scss" scoped />

<style lang="scss">
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_colors.scss';

    .ql-toolbar.ql-snow{
        border-radius: 12px 12px 0 0;
        background: $color-text-tertiary;
    }

    .ql-container.ql-snow{
        margin-bottom: 15px;
        border-radius: 0 0 12px 12px;
        background: $color-text-tertiary;

        @media (max-width: 576px){
            height: 260px;
        }
        
        @media (min-width: 576px){
            height: 380px;
        }
    }

    .ql-editor {
        font-family: $font-primary-regular;
        
        @media (max-width: 576px){
            font-size: 13px;
            line-height: 20px;
        }

        @media (min-width: 576px){
            font-size: 16px;
            line-height: 24px;
        }
    }

    .ql-editor h2,
    .ql-editor h3,
    .ql-editor h4,
    .ql-editor h5,
    .ql-editor h6 {
        font-family: $font-secondary-bold;
    }

    .editor-html{
        &.error{
            .ql-toolbar.ql-snow, .ql-container.ql-snow{
                border-color: $color-cancel;
            }
        }
    }
</style>