<template>
  <div class="dne-market company-registration">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <section class="content-company-registration">
            <div class="container">
                <div class="box">
                    <div class="scroll-text">
                        <h1 class="title-secondary">{{getMyLanguage("company-registration","registration.title")}}</h1>

                        <div class="timeline">
                            <div class="line" data-aos="fade-up" data-aos-offset="-200">
                                <div class="date">2023</div>
                                <div class="cont">
                                    <h3 v-html='getMyLanguage("company-registration","line-08.title")'></h3>
                                    <p>{{ getMyLanguage("company-registration","line-08.description") }}</p>
                                    <a href="https://so-fit.ch/recherche/" target="_blank" class="btn-view">{{ getMyLanguage("company-registration","cta-website") }}</a>
                                </div>
                            </div>
                            
                            <div class="line" data-aos="fade-up" data-aos-offset="-200">
                                <div class="date">2023</div>
                                <div class="cont">
                                    <h3 v-html='getMyLanguage("company-registration","line-07.title")'></h3>
                                    <p>{{ getMyLanguage("company-registration","line-07.description") }}</p>
                                    <a href="https://www.finma.ch/en/authorisation/self-regulatory-organisations-sros/sro-member-search" target="_blank" class="btn-view">{{ getMyLanguage("company-registration","cta-website") }}</a>
                                </div>
                            </div>
                            
                            <div class="line" data-aos="fade-up" data-aos-offset="-200">
                                <div class="date">2023</div>
                                <div class="cont">
                                    <h3 v-html='getMyLanguage("company-registration","line-06.title")'></h3>
                                    <p>{{ getMyLanguage("company-registration","line-06.description") }}</p>
                                    <a href="https://cdn.dneecosystem.com/docs/Attestation-SO-FIT.pdf" target="_blank" class="btn-view">{{ getMyLanguage("company-registration","cta-document") }}</a>
                                </div>
                            </div>

                            <div class="line" data-aos="fade-up" data-aos-offset="-200">
                                <div class="date">2022</div>
                                <div class="cont">
                                    <h3 v-html='getMyLanguage("company-registration","line-05.title")'></h3>
                                    <p>{{ getMyLanguage("company-registration","line-05.description") }}</p>
                                    <a href="https://cdn.dneecosystem.com/docs/Certificate-of-Registration-Coin.pdf" target="_blank" class="btn-view">{{ getMyLanguage("company-registration","cta-document") }}</a>
                                </div>
                            </div>
                            
                            <div class="line" data-aos="fade-up" data-aos-offset="-200">
                                <div class="date">2022</div>
                                <div class="cont">
                                    <h3 v-html='getMyLanguage("company-registration","line-04.title")'></h3>
                                    <p>{{ getMyLanguage("company-registration","line-04.description") }}</p>
                                    <a href="https://cdn.dneecosystem.com/docs/Certificate-of-Registration-Brand.pdf" target="_blank" class="btn-view">{{ getMyLanguage("company-registration","cta-document") }}</a>
                                </div>
                            </div>

                            <div class="line" data-aos="fade-up" data-aos-offset="-200">
                                <div class="date">2022</div>
                                <div class="cont">
                                    <h3 v-html='getMyLanguage("company-registration","line-03.title")'></h3>
                                    <p>{{ getMyLanguage("company-registration","line-03.description") }}</p>
                                </div>
                            </div>

                            <div class="line" data-aos="fade-up" data-aos-offset="-200">
                                <div class="date">2022</div>
                                <div class="cont">
                                    <h3 v-html='getMyLanguage("company-registration","line-02.title")'></h3>
                                    <p>{{ getMyLanguage("company-registration","line-02.description") }}</p>
                                    <a href="https://cdn.dneecosystem.com/docs/DNE-International-Sagl.pdf" target="_blank" class="btn-view">{{ getMyLanguage("company-registration","cta-document") }}</a>
                                </div>
                            </div>
                            
                            <div class="line" data-aos="fade-up" data-aos-offset="-200">
                                <div class="date">2021</div>
                                <div class="cont">
                                    <h3 v-html='getMyLanguage("company-registration","line-01.title")'></h3>
                                    <p>{{ getMyLanguage("company-registration","line-01.description") }}</p>
                                    <a href="https://cdn.dneecosystem.com/docs/Holding-DNE.pdf" target="_blank" class="btn-view">{{ getMyLanguage("company-registration","cta-document") }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <LegalPolicies />
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Essentials/Header.vue';
    import LegalPolicies from "@/components/Essentials/LegalPolicies.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header,
            LegalPolicies
        },

        data (){
            return {
                headerTemplate: "search",
            }
        },

        async mounted() {
            this.languageSelected();
            this.goTop()
        },

        methods: {
            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            document.title = this.getMyLanguage("seo", "title.privacy");
            document.head.querySelector("meta[name=description]").content =
            this.getMyLanguage("seo", "title.description.privacy");
        },
    };
</script>

<style src="./custom-company-registration.scss" lang="scss" scoped />

<style lang="scss">
    @import '@/assets/scss/_colors.scss';
    
    .timeline .line .cont h3{
        small{
            display: block;
            color: $color-secondary;
        }
    }
</style>