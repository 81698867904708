<template>
  <div class="dne-market termsPolicies">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <section class="content-terms">
            <div class="container-fluid">
                <div class="box">
                    <div class="scroll-text">
                        <h1 class="title-secondary">
                            {{getMyLanguage("LegalPolicies","privacy.title")}}
                        </h1>
                        
                        <p><small>Website Privacy Policy</small></p>
                        <h3>DNE INTERNATIONAL SAGL collects and processes some personal data about you.</h3>
                        
                        <p><span>What is “personal data”?</span> Personal data are defined per reference to the Regulation 2016/679/EU of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and any other law or regulation applicable in Switzerland (hereafter the <strong>Laws and Regulation on Personal Data</strong>) as any information relating to an identified or identifiable natural person.</p>
                        <p>This includes any data through which a natural person can be identified directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person (hereafter Personal Data).</p>
                        <p><span>How does DNE INTERNATIONAL SAGL collect personal data?</span> In general, access and navigation on the Website can be performed without disclosing any personal information or data. However, on some parts of this Website, it may be needed to collect some information or you may submit this information directly to <strong>DNE INTERNATIONAL SAGL.</strong></p>
                        <p>All Personal Data processed by <strong>DNE INTERNATIONAL SAGL</strong> are provided by the user (hereinafter the <strong>Data Subject</strong>) through:</p> 
                        
                        <ul>
                            <li>Online forms</li>
                            <li>Subscription to newsletters/blog or request for information and services</li>
                            <li>Sending of a candidature through the careers section</li>
                            <li>Cookies</li>
                        </ul>
                        
                        <p><span>What categories of data does DNE INTERNATIONAL SAGL collect?</span> On top of cookies, the Personal Data collected, registered and processed, for instance through online forms, can include:</p>
                        <p>– Identification Personal Data, such as, but not limited to names and surnames</p>
                        <p>– Identification Personal Data issued by public authorities such as social security number, ID or passport</p>
                        <p>– Electronic Identification Personal Data such as email addresses</p>
                        <p>– Electronic location Personal Data such as mobile phone number</p>
                        <p>– Professional Personal Data, such as activity and occupation</p>
                        <p>– Particular financial Personal Data such as Tax Identification Number and Annual Income</p>
                        <p>– Personal characteristics Personal Data such as age and gender</p>
                        <p>– Household composition Personal Data such as marital status</p>
                        <p>– Studies and Education Personal Data such as professional qualifications</p>
                        
                        <p>&nbsp;</p>
                        <p>In the frame of this Website, Personal Data will not comprise sensitive data about the Data Subjects such as related to physical or mental health.</p>
                        <p>In case of questions please do not hesitate to contact us <a href="mailto:support@dnetoken.com">support@dnetoken.com</a></p>
                        
                        <p>&nbsp;</p>
                        <p><strong>Purposes and legal basis of processing</strong></p>
                        <p>The user understands that, in order to use the Website and its related services, it is compulsory for DNE INTERNATIONAL SAGL to collect several Personal Data for its <strong>legitimate interest</strong> in which case DNE INTERNATIONAL SAGL will take care to preserve a proportionate balance between the interest pursued and the respect for privacy of the Data Subject. In this context, DNE INTERNATIONAL SAGL will collect and treat the Personal Data in accordance with the Laws and Regulation on Personal Data and this Website Privacy Policy for following main purposes:</p>
                        <p>If the Data Subject gave his <strong>consent</strong> to such processing, DNE INTERNATIONAL SAGL will also process the Personal Data:</p>
                        
                        <p>&nbsp;</p>
                        <p><strong>Automated decision making and profiling</strong></p>
                        <p>The Personal Data collected may be processed partially through automated means and notably for the performance of the legal duties of DNE INTERNATIONAL SAGL.</p>
                        <p>No decision will be made entirely based on automated Personal Data Processing and the decision will ultimately lie with the staff/management of DNE INTERNATIONAL SAGL. In particular, CV will not be subject to a decision based on automated processing. DNE INTERNATIONAL SAGL carries out regular checks to make sure its systems are working as intended.</p>
                        <p>The Personal Data collected may be processed partially for profiling purposes on top of statistical purposes, when necessary to subscribe to the proposed services, and notably for the performance of the legal duties of DNE INTERNATIONAL SAGL (such as, but not limited to, anti-money laundering obligations).</p>
                        
                        <p>&nbsp;</p>
                        <p><strong>Duration of processing</strong></p>
                        <p>a. <strong>CV sent through the website</strong></p>
                        <p>Personal data sent to DNE INTERNATIONAL SAGL through the careers section will be kept no longer than one year by our HR department.</p>
                        <p>b. <strong>Other Personal Data</strong></p>
                        <p>The Personal Data collected for the purposes explained under point 3, will be stored in DNE Token’s systems as long as the Data Subject doesn’t withdraw his consent by using the unsubscribe function when receiving communications (newsletters, etc) by email or by contacting us (please refer to point 10 below) and for no longer than 24 months.</p>
                        
                        <p>&nbsp;</p>
                        <p><strong>Transfer of Personal Data</strong></p>
                        <p>DNE INTERNATIONAL SAGL reserves the right to transfer the Personal Data to its suppliers in order to comply with its obligations under this legal and Privacy Policy or for the execution of the services requested by the user:</p>
                        
                        <ul>
                            <li>To other companies used by DNE INTERNATIONAL SAGL for archiving</li>
                            <li>To other companies used by DNE INTERNATIONAL SAGL for keeping contact with the Data Subject (such as but not limited to postal service and telecommunication companies)</li>
                            <li>To any other persons specially appointed or authorized by the Data Subject (even in third countries if specifically requested by the Data Subject)</li>
                            <li>To those persons or authorities to whom the law or another regulation requires or authorizes DNE INTERNATIONAL SAGL to disclose such data, under the terms provided for in the aforementioned Laws and Regulations on Personal Data or any other law or regulation applicable to DNE INTERNATIONAL SAGL, such as the tax administration or under a relevant court decision.</li>
                        </ul>
                        
                        <p>The Data Subject is entitled to request details about those transfers at any time (please refer to point 10 below).</p>
                        <p>DNE INTERNATIONAL SAGL does not perform any data processing operations in a country located outside the EEA. Should DNE INTERNATIONAL SAGL change its Policy in this respect, the Data Subject will be informed beforehand.</p>
                        <p><strong>Identity and contact details of the Data Controller and of the Data Protection Officer</strong></p>
                        <p>The Data Controller is DNE INTERNATIONAL SAGL:</p>
                        <p>Via Cantonale, 11</p>
                        <p>6900 - Lugano (Ticino) - Swizerland</p>
                        <p>DNE INTERNATIONAL SAGL’s Data Protection Officer (hereafter the “DPO”) can be contacted by mail at the address of DNE Token or through the specific email address:</p>
                        <p><a href="mailto:support@dnetoken.com">support@dnetoken.com</a></p>
                        
                        <p>&nbsp;</p>
                        <p><strong>Rights of the Data Subject</strong></p>
                        <p><span>What are my rights?</span> In compliance with the Laws and Regulations on Personal Data and any other applicable law or regulation, the Data Subject has the right to:</p>
                        
                        <ol>
                            <li>Obtain transparent information on the Personal Data collected and the processing completed on this Personal Data collected</li>
                            <li>Request from DNE INTERNATIONAL SAGL access to Personal Data, and a.o. to get information on the purpose of the processing, the categories of personal data concerned and recipients or categories of recipients to whom the personal data have been disclosed</li>
                            <li>Request a rectification of Personal Data i.e. to request that specific Personal Data be rectified from DNE Token’s systems where one of the legal grounds is applicable</li>
                            <li>Request a restriction of processing, i.e. provided one of the legal grounds is applicable, to request that Personal Data are, with the exception of storage, only processed with the Data Subject’s consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person</li>
                            <li>Object, on grounds relating to his or her particular situation, to processing based on the legitimate interest of DNE INTERNATIONAL SAGL.</li>
                            <li>Request a portability, i.e. the right to receive the Personal Data concerning him or her, which he or she has provided to a controller, in a structured, commonly used and machine-readable format and have the right to transmit those data to another controller, if technically feasible</li>
                            <li>Use his right to be forgotten, i.e. to request an erasure of DNE Token’s entire records of Personal Data about him, subject to the provisions concerning pseudonymisation and anonymization</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <LegalPolicies />
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Essentials/Header.vue';
    import LegalPolicies from "@/components/Essentials/LegalPolicies.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header,
            LegalPolicies
        },

        data (){
            return {
                headerTemplate: "search",
            }
        },

        async mounted() {
            this.languageSelected();
            this.goTop()
        },

        methods: {
            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            document.title = this.getMyLanguage("seo", "title.privacy");
            document.head.querySelector("meta[name=description]").content =
            this.getMyLanguage("seo", "title.description.privacy");
        },
    };
</script>

<style src="./custom-terms.scss" lang="scss" scoped />
