<template>
    <div class="load" v-if="load"></div>
    <section class="mosaic-home" v-else>
        <h2 class="title-secondary">{{ getMyLanguage("MosaicHighlights", "mosaic.title") }}</h2>

        <div class="container-fluid">
            <div class="row">
                <div v-for="(col, colIndex) in columns" :key="`col-${colIndex}`" :class="col.class">
                    <a
                        v-for="(item, itemIndex) in col.items"
                        :key="`item-${itemIndex}`"
                        :href="item.url"
                        :class="['mosaic', item.class]"
                        data-aos="fade-up"
                        data-aos-offset="-100">

                        <figure>
                            <img :src="item.background" class="banner-mosaic" :alt="item.name" />
                            <figcaption>
                                <h2>{{ item.name }}</h2>
                            </figcaption>
                        </figure>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import apiMarket from "@/config/apiMarket"
    import getMyContentLanguage from "@/services/contentLanguage.js"

    export default {
        data() {
            return {
                flagSelected: "en",
                load: true,
                dataMosaic: [],
                columns: [],
            }
        },
        
        async mounted() {
            try {
                const response = await apiMarket.get("api/v1/category/banners")
                this.dataMosaic = response.data
                this.organizeColumns()

            } catch (error) {
                console.error(error)
                this.load = false
            }
        },

        methods: {
            languageSelected() {
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            organizeColumns() {
                const structure = [
                    { class: "col-6", items: 1, itemClasses: ["full"] },
                    { class: "col-6 col-lg-3", items: 2, itemClasses: ["high", "low"] },
                    { class: "col-12 col-lg-3 d-flex d-lg-block", items: 2, itemClasses: ["low", "high"] }
                ]

                let currentIndex = 0;

                this.columns = structure.map((colStruct) => {
                    const colItems = this.dataMosaic.slice(currentIndex, currentIndex + colStruct.items).map((item, index) => ({
                        ...item,
                        class: colStruct.itemClasses[index] || "",
                    }));
                    currentIndex += colStruct.items
                    return { class: colStruct.class, items: colItems }
                });

                this.load = false
            }
        },

        watch: {
            "$store.state.SelectLanguage.code": {
                immediate: true,
                handler() {
                    this.languageSelected()
                }
            }
        },
    };
</script>

<style src="@/assets/scss/_mosaic-home.scss" lang="scss" scoped />