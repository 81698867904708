const $ = require('jquery')
window.$ = $

import apiMerchant from '@/config/apiMerchant.js'

import bus from '@/eventBus';

export default {
    data() {
        return {
            flagSelected: "en",
            headerTemplate: "merchant",
            breadcrumb: "",
            load: true,

            dataMerchantStore: {},
            loadMerchantStore: true,

            sectionManage: {
                load: true,
                guide: "plugin",

                loaded: {
                    plugin: false,
                    webhook: false,
                    logs: false
                },

                copiyng: false
            },

            storeName: "",
        }
    },

    async mounted() {
        this.languageSelected()

        // HEADER STORE
        await apiMerchant('/api/v1/market/store')
        .then(response => {
            setTimeout(() => {
                this.dataMerchantStore = response.data.store
                this.loadMerchantStore = false

                this.pluginCode.store_id = this.dataMerchantStore.id
            }, 500);
        })
        .catch(error => {
            setTimeout(() => {
                this.errorsGet.dataMerchantStore = error.response.status
                this.loadMerchantStore = false
                localStorage.removeItem("storeReference")

                if ( error.response.status == 401 ){
                    bus.emit('open-login',"401");
                }
            }, 500);
        })

        this.storeName = localStorage.getItem("storeName")

        this.breadcrumb = [
            {
                slug: `${this.flagSelected}/merchant/dashboard`,
                name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
            },
            {
                // name: this.getMyLanguage("Merchant","campaigns.title")
                name: "Plugin DNE Pay"
            }
        ]
    },

    methods: {
        goTop(){
            $('html, body').animate({scrollTop : 0},200);
            return false;
        },

        // NAVIGATION SECTIONS
        navSection(value){
            if ( value == "plugin" ){
                this.sectionManage.guide = "plugin"
                this.goTop()
            }
            
            if ( value == "webhook" ){
                this.sectionManage.guide = "webhook"
                
                this.goTop()

                if ( !this.sectionManage.loaded.webhook ){
                    this.loadWebHook()
                }
            }

            if ( value == "logs" ){
                this.sectionManage.guide = "logs"
                
                this.goTop()

                if ( !this.sectionManage.loaded.logs ){
                    this.loadLogs()
                }
            }
        },


        // COPY CODE
        copyCode(value) {
            navigator.clipboard.writeText(value).then(() => {
                this.sectionManage.copying = true
                this.$toast.success("Code copied to clipboard!")

                setTimeout(() => {
                    this.sectionManage.copying = false
                }, 500);
            })
        }
    }
}