import apiMerchant from '@/config/apiMerchant.js'

import bus from '@/eventBus';

export default {
    data(){
        return{
            dataWebHook: {
                types: [],
                webhook: []
            },

            errorsGet: {
                dataWebHook: {
                    types: false,
                    webhook: false
                }
            },

            webhookPlace: "list",

            webhookManager: {
                action: "",
                code: "",
                type: "",
                copying: false,
                secret_rotate: false,

                data: {
                    name: "",
                    address: "",
                    type: "",
                    actions: [],
                    security_key: ""
                }
            },

            errorForm: {
                webhook: {
                    name: "",
                    address: "",
                    type: "",
                    actions: ","
                }
            },

            loadSave: false,

            flowDelete: false
        }
    },

    methods: {
         // WEBHOOK
         async loadWebHook(){
            this.sectionManage.load = true

            try {
                const responseWebHook = await apiMerchant("/api/v2/plugin/webhook")
                this.dataWebHook.webhook = responseWebHook.data

                this.sectionManage.loaded.webhook = true
                
                setTimeout(() => {
                    this.sectionManage.load = false
                }, 500);

                this.loadTypesWebHook()
            }
            catch (error) {
                this.errorsGet.dataWebHook.webhook = JSON.stringify(error.response.status)
                this.sectionManage.load = false
                console.error(error)

                if ( error.response.status == 401 ){
                    bus.emit('open-login',"401");
                }
            }
        },


        // WEBHOOK > TYPES
        async loadTypesWebHook(){
            try {
                const responseTypesWebHook = await apiMerchant("/api/v2/plugin/webhook/types")
                this.dataWebHook.types = responseTypesWebHook.data
            }
            catch (error) {
                this.errorsGet.dataWebHook.types = JSON.stringify(error.response.status)
                console.error(error)
            }
        },


        filterWebHook() {
            let a = '';
            let i = '';
            let txtValue = '';
            const inputList = document.getElementById("searchWebHook");
            const filter = inputList.value.toUpperCase();
            const ul = document.getElementById("drop-list-webhook");
            const li = ul.getElementsByTagName("tr");
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("td")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        
        openWebHook(action, value) {
            this.webhookPlace = 'manager';
            this.webhookManager.action = action;
        
            this.webhookManager.data = {};
            this.webhookManager.data.actions = [];
        
            if (action == 'upd') {
                console.log(value);
        
                this.webhookManager.data.name = value.name;
                this.webhookManager.data.address = value.address;
                this.webhookManager.data.secret_rotate = false;
                this.webhookManager.data.type = value.type.type_code;
                // this.webhookManager.data.actions = value.actions.map(action => action.Id)
                this.webhookManager.data.actions = value.actions
        
                this.webhookManager.data.Id = value.Id;
                this.webhookManager.data.secret = value.secret;

                
                // GENERATE WEBHOOK CODE
                const selectedType = this.dataWebHook.types.find(
                    (type) => type.type_code === this.webhookManager.data.type
                );
        
                if (selectedType) {
                    const selectedActions = selectedType.actions.filter((action) =>
                        this.webhookManager.data.actions.includes(action.Id)
                    );

                    let codeWithComments = selectedActions.map((action) => {
                        return `// Action: ${action.action}\n${JSON.stringify(action, null, 2)}`;
                    });
        
                    this.webhookManager.code = codeWithComments.join("\n\n");
                } else {
                    this.webhookManager.code = "";
                }
            }
        },
        
        closeWebHook(){
            this.webhookPlace = 'list',
            this.webhookManager.action = ""
            
            this.webhookManager.data = {}
            this.webhookManager.data.actions = []

            this.loadSave = false
            this.flowDelete = false
        },


        // WEBHOOK > MANAGER
        validateAddress() {
            const urlPattern = new RegExp(
              "^(https?:\\/\\/)?" + // Protocol (optional)
              "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // Domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // IP address
              "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*" + // Port and path
              "(\\?[;&a-zA-Z0-9@:%._\\+~#=]*)?" + // Query string
              "(\\#[-a-zA-Z0-9_]*)?$", // Fragment identifier
              "i"
            );
      
            if (!urlPattern.test(this.webhookManager.data.address)) {
              this.errorForm.webhook.address = true;
              console.log("asdf")
            } else {
              this.errorForm.webhook.address = false;
            }
        },

        handleCheckboxChange(item, event) {
            const isChecked = event.target.checked;
        
            if (isChecked) {
              this.webhookManager.data.actions.push(item.Id);
            } else {
              this.webhookManager.data.actions = this.webhookManager.data.actions.filter(
                (id) => id !== item.Id
              );
            }
        
            const selectedType = this.dataWebHook.types.find(
              (type) => type.type_code === this.webhookManager.data.type
            );
        
            if (selectedType) {
              const selectedActions = selectedType.actions.filter((action) =>
                this.webhookManager.data.actions.includes(action.Id)
              );
        
              // JSON CODE
              let codeWithComments = selectedActions.map((action) => {
                return `// Action: ${action.action}\n${JSON.stringify(action, null, 2)}`;
              });
        
              this.webhookManager.code = codeWithComments.join("\n\n");
            } else {
              this.webhookManager.code = "";
            }
        },

        handleSecretRotateChange() {
            const originalValue = !this.webhookManager.data.secret_rotate;
        
            if (this.webhookManager.data.secret_rotate) {
                const userConfirmed = window.confirm(
                    "Are you sure you want to change the Secret (SHA-256)?"
                );
        
                if (!userConfirmed) {
                this.webhookManager.data.secret_rotate = originalValue;
                }
            }
        },


        // WEBHOOK > SAVE
        saveWebhook(){
            this.loadSave = true

            if ( this.webhookManager.action == "new" ){
                try {
                    apiMerchant.post("/api/v2/plugin/webhook", this.webhookManager.data)
                    
                    setTimeout(() => {
                        this.$toast.success("Webhook created")
                        this.loadWebHook()
                        this.closeWebHook()

                        this.loadSave = false
                    }, 500);
                }
                catch (error){
                    console.error(error)

                    this.$toast.error("Webhook not created")
                    this.loadSave = false
                }
            }

            if ( this.webhookManager.action == "upd" ){
                try {
                    apiMerchant.put(`/api/v2/plugin/webhook/${this.webhookManager.data.Id}`, this.webhookManager.data)
                    
                    setTimeout(() => {
                        this.$toast.success("Webhook updated")
                        this.loadWebHook()
                        this.closeWebHook()

                        this.loadSave = false
                    }, 500);
                }
                catch (error){
                    console.error(error)

                    this.$toast.error("Webhook not updated")
                    this.loadSave = false
                }
            }
        },

        deleteWebhook(){
            this.loadSave = true

            try {
                apiMerchant.delete(`/api/v2/plugin/webhook/${this.webhookManager.data.Id}`)

                setTimeout(() => {
                    this.$toast.success("Webhook deleted")
                    this.loadWebHook()
                    this.closeWebHook()

                    this.loadSave = false
                }, 500);
            }
            catch (error){
                console.error(error)
                
                this.$this.error("Webhook not deleted")
                this.loadSave = false
            }
        }
    },
}