<template>
    <div class="merchant page-store">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />
        
        <!-- CONTENT -->
        <section class="profile">
            <div class="load load-white load-200" v-if="load"></div>

            <!-- ERROR RESTRICTED -->
            <div class="container" v-if="!load && errorsGetGeneral.dataMerchant != false">
                <div class="box box-error" :class="{error403: errorsGetGeneral.dataMerchant == '403'}">
                    <h3 class="title-secondary">
                        {{getMyLanguage("box-error","title-error")}}
                    </h3>

                    <p v-if='errorsGetGeneral.dataMerchant == "401"'>
                        {{getMyLanguage("box-error","401")}}
                    </p>
                    
                    <div class="error-403" v-else-if='errorsGetGeneral.dataMerchant == "403"'>
                        <img src="~@/assets/images/icons/403.svg" alt="Error 403">

                        <p>{{getMyLanguage("box-error","403")}}</p>
                    </div>

                    <p v-else>
                        {{getMyLanguage("box-error","msg-error")}}
                    </p>
                </div>
            </div>

            
            <!-- BOX REGISTRATION -->
            <div class="container" v-if='!load && !errorsGetGeneral.dataMerchant'>
            
                <!-- STEPS -->
                <div class="steps">
                    <div class="cont" :class="flowStep.stp">
                        <div class="step step-01"
                            :class="{active: flowStep.stp == 'step01', validated: flowStep.statusStep01 || dataMerchant.summary.company, pending: !dataMerchant.summary.company}"
                            @click='navStep("stp01")'>

                            <span><i>1</i></span>

                            <small v-html="getMyLanguage('Store','merchant.store.menu-steps.step01')"></small>
                        </div>

                        <div class="step step-02"
                            :class="{active: flowStep.stp == 'step02', validated: flowStep.statusStep02 || dataMerchant.summary.partners, disabled: newCompany, pending: !dataMerchant.summary.partners}"
                            @click='!newCompany && navStep("stp02")'>

                            <span><i>2</i></span>

                            <small v-html="getMyLanguage('Store','merchant.store.menu-steps.step02')"></small>
                        </div>

                        <div class="step step-03"
                            :class="{active: flowStep.stp == 'step03', validated: flowStep.statusStep03 || dataMerchant.summary.documents, disabled: newCompany, pending: !dataMerchant.summary.documents}"
                            @click='!newCompany && navStep("stp03")'>

                            <span><i>3</i></span>

                            <small v-html="getMyLanguage('Store','merchant.store.menu-steps.step03')"></small>
                        </div>
                    </div>
                </div>


                <div class="box-profile">
                    <div class="load" v-if="flowStep.load"></div>

                    <!-- BLOCK CREATE -->
                    <div class="cont-step" v-if='flowStep.stp == "step01" && !flowStep.load && blockCreate'>
                        <h2 class="title-secondary">
                            {{getMyLanguage("Store","merchant.store.title-steps.step01")}}
                        </h2>

                        <div class="box box-pending">
                            <p>{{ getMyLanguage("Store","merchant.store.steps.block-create") }}</p>
                        </div>
                    </div>

                    <!-- STEP 01 / COMPANY -->
                    <div class="cont-step" v-if='flowStep.stp == "step01" && !flowStep.load && !blockCreate'>
                        
                        <h2 class="title-secondary">
                            {{getMyLanguage("Store","merchant.store.title-steps.step01")}}
                        </h2>

                        <div class="box-registration box-information" :class="dataMerchant.status" v-if="dataMerchant.status != 'None'">
                            <div class="status" v-if="dataMerchant.status == 'Active'">
                                <h3>{{ getMyLanguage("Store","merchant.box-information.status-active.title") }}</h3>
                                <p>{{ getMyLanguage("Store","merchant.box-information.status-active.txt") }}</p>
                            </div>
                            
                            <div class="status" v-if="dataMerchant.status == 'Accepted'">
                                <h3>{{ getMyLanguage("Store","merchant.box-information.status-accepted.title") }}</h3>
                                <p>{{ getMyLanguage("Store","merchant.box-information.status-accepted.txt") }}</p>
                            </div>

                            <div class="status" v-if="dataMerchant.status == 'Review'">
                                <h3>{{ getMyLanguage("Store","merchant.box-information.status-review.title") }}</h3>
                                <p>{{ getMyLanguage("Store","merchant.box-information.status-review.txt") }}</p>
                            </div>
                            
                            <div class="status" v-if="dataMerchant.status == 'Pending'">
                                <h3>{{ getMyLanguage("Store","merchant.box-information.status-pending.title") }}</h3>
                                <p>{{ getMyLanguage("Store","merchant.box-information.status-pending.txt") }}</p>
                            </div>
                            
                            <div class="status" v-if="dataMerchant.status == 'Rejected'">
                                <h3>{{ getMyLanguage("Store","merchant.box-information.status-rejected.title") }}</h3>
                                <p>{{ getMyLanguage("Store","merchant.box-information.status-rejected.txt") }}</p>
                            </div>

                            <div class="status" v-if="dataMerchant.status == 'Inactive'">
                                <h3>{{ getMyLanguage("Store","merchant.box-information.status-inactive.title") }}</h3>
                                <p>{{ getMyLanguage("Store","merchant.box-information.status-inactive.txt") }}</p>
                            </div>
                        </div>

                        <div class="box-registration" ref="meuFormulario">
                            <div class="row formBox">                                        

                                <!-- COMPANY NAME -->
                                <div class="col-12 col-lg-6">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyName")}}
                                            
                                            <i><span>{{ getMyLanguage("Store",'merchant.store.lbl.companyName.info') }}</span></i>

                                            <div class="open-video-info" @click="openVideoInfo('campany-name')"></div>
                                        </span>

                                        <input type="text" class="form" maxlength="80"
                                            v-if="dataCompliance.backOfficeStatus != 'Accepted'"
                                            v-model="dataMerchant.legal_name"
                                            :class='{error: errorForm.company.legal_name}'
                                            @keydown="errorForm.company.legal_name = false">

                                        <div v-if="dataCompliance.backOfficeStatus == 'Accepted'" class="form form-disabled">
                                            <span>{{dataMerchant.legal_name}}</span>
                                        </div>

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.legal_name'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                    </label>

                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyName")}}
                                            
                                            <i><span>{{ getMyLanguage("Store",'merchant.store.lbl.companyName.info') }}</span></i>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.legal_name}}
                                        </div>
                                    </label>
                                </div>
                                
                                <!-- FANTASY NAME -->
                                <div class="col-12 col-lg-6">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyFantasyName")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyFantasyName.info")}}</span></i>

                                            <div class="open-video-info" @click="openVideoInfo('campany-name')"></div>
                                        </span>

                                        <input type="text" class="form" maxlength="80"
                                            v-model="dataMerchant.fantasy_name"
                                            :class='{error: errorForm.company.fantasy_name}'
                                            @keydown="errorForm.company.fantasy_name = false">
                                        
                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.fantasy_name'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                    </label>

                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyFantasyName")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyFantasyName.info")}}</span></i>

                                            <div class="open-video-info" @click="openVideoInfo('campany-name')"></div>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.fantasy_name}}
                                        </div>
                                    </label>
                                </div>

                                <!-- COMPANY TYPE -->
                                <div class="col-12 col-lg-6">
                                    <label>
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyType")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyType.info")}}</span></i>
                                        </span>

                                        <select class="form"
                                            v-model="dataMerchant.type"
                                            :class='{error: errorForm.company.type}'
                                            @change="errorForm.company.type">

                                            <option value="" disabled selected hidden>
                                                {{getMyLanguage("Store","merchant.store.option.select")}}
                                            </option>

                                            <option
                                                v-for="(itemsCompanyType,indexCompanyType) in dataCompanyType"
                                                :key="indexCompanyType"
                                                :value="itemsCompanyType.Id">

                                                {{itemsCompanyType.Value}}
                                            </option>
                                        </select>

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.type'>
                                            {{getMyLanguage("errors","errors.selectOption")}}
                                        </p>
                                    </label>
                                </div>

                                <!-- COMPANY INDUSTRY / SEGMENT -->
                                <div class="col-12 col-lg-6">
                                    <label>
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companySegment")}}
                                            
                                            <i><span></span></i>
                                        </span>

                                        <select class="form"
                                            v-model="dataMerchant.industry"
                                            :class='{error: errorForm.company.industry}'
                                            @change="errorForm.company.industry">

                                            <option value="" disabled selected hidden>
                                                {{getMyLanguage("Store","merchant.store.option.select")}}
                                            </option>

                                            <option
                                                v-for="(itemsCompanyIndustry,indexCompanyIndustry) in dataCompanyIndustry"
                                                :key="indexCompanyIndustry"
                                                :value="itemsCompanyIndustry.Id">

                                                {{itemsCompanyIndustry.Value}}
                                            </option>
                                        </select>

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.type'>
                                            {{getMyLanguage("errors","errors.selectOption")}}
                                        </p>
                                    </label>
                                </div>

                                <!-- CURRENCY COMPANY -->
                                <div class="col-12 col-lg-6" v-if="dataCurrency">
                                    <div class="autocomplete has-label no-icon formLanguage" :class="{opened:listCurrenciesCompanyStatus}">

                                        <div class="openAutocomplete"
                                            v-if="!listCurrenciesCompanyStatus"
                                            @click="dropListCurrencies(true,'company')"></div>

                                        <div class="closeAutocomplete closeDropListCountry"
                                            v-if="listCurrenciesCompanyStatus"
                                            @click="dropListCurrencies(false,'company')"></div>

                                        <label>
                                            <span class="info">
                                                {{getMyLanguage("Store","merchant.store.lbl.currency")}}
                                                
                                                <i><span>{{getMyLanguage("Store","merchant.store.lbl.currency.info")}}</span></i>
                                            </span>

                                            <i class="currency-symbol-selected">
                                                {{dataCurrency.filter(cc => cc.quote_asset.id == dataMerchant.asset_id)[0].quote_asset.symbol}}
                                            </i>

                                            <input type="text" id="currencyIDcompany" class="form"
                                                :class="{selected: dataMerchant.asset_id, error: errorForm.company.asset_id}"
                                                @focus="dropListCurrencies(true,'company')"
                                                @keyup="filterListCurrency('company')"
                                                @blur="dropListCurrencies(false,'company')"
                                                @keydown.esc="dropListCurrencies(false,'company')"
                                                :value="getMyLanguage('Currency','asset.' + dataCurrency.filter(cc => cc.quote_asset.id == dataMerchant.asset_id)[0].quote_asset.short)"
                                                :title='getMyLanguage("Store", "merchant.store.lbl.curency")'>

                                            <p class="msg-validate-form msg-error anime" v-if='errorForm.company.type'>
                                                {{getMyLanguage("errors","errors.selectOption")}}
                                            </p>
                                        </label>

                                        <div class="cont-autocomplete" v-if="listCurrenciesCompanyStatus">
                                            <ul id="drop-list-company">
                                                <li v-for="(itemList,indexCurrencyCompany) in dataCurrency"
                                                    :key="indexCurrencyCompany"
                                                    @click="dataMerchant.asset_id = itemList.quote_asset.id, dropListCurrencies(false,'company'), errorForm.company.type = false">
                                                    
                                                    <div class="currency">
                                                        <i class="currency-symbol" :class="itemList.quote_asset.symbol">
                                                            {{itemList.quote_asset.symbol}}
                                                        </i>

                                                        <a>{{getMyLanguage("Currency","asset." + itemList.quote_asset.short)}}</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <!-- LANGUAGE COMPANY -->
                                <div class="col-12 col-lg-6" v-if="dataLanguage">
                                    <div class="autocomplete has-label no-icon formLanguage" :class="{opened: dropListOpened.languageCompany}">
                                        <div class="openAutocomplete"
                                            v-if="!listLanguagesCompanyStatus"
                                            @click="dropListLanguages(true,'company')"></div>

                                        <div class="closeAutocomplete closeDropListCountry"
                                            v-if="listLanguagesCompanyStatus"
                                            @click="dropListLanguages(false,'company')"></div>

                                        <label>
                                            <span class="info">
                                                {{getMyLanguage("Store","merchant.store.lbl.language")}}
                                                
                                                <i><span>{{getMyLanguage("Store","merchant.store.lbl.language.info")}}</span></i>
                                            </span>

                                            <img class="flag-selected"
                                                :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageCompanyFlag}/flags`"
                                                v-if="selectedLanguageCompanyFlag != false">

                                            <input type="text" id="languageIDcompany" class="form"
                                                :class="{selected: selectedLanguageCompanyFlag != false, error: errorForm.company.language_id}"
                                                @focus="dropListLanguages(true,'company')"
                                                @keyup="filterListLanguage('company')"
                                                @blur="dropListLanguages(false,'company')"
                                                @keydown.esc="dropListLanguages(false,'company')"
                                                :value="languagesList(selectedLanguageCompany.code)"
                                                :title='getMyLanguage("Store", "merchant.store.lbl.language")'>

                                            <p class="msg-validate-form msg-error anime" v-if='errorForm.company.language_id'>
                                                {{getMyLanguage("errors","errors.language")}}
                                            </p>
                                        </label>

                                        <div class="cont-autocomplete" v-if="listLanguagesCompanyStatus">
                                            <ul id="drop-list-company">
                                                <li v-for="(itemList,indexLanguageCompany) in dataLanguage"
                                                    :key="indexLanguageCompany"
                                                    @click="dataMerchant.language_id = itemList.id,
                                                        selectedLanguageCompany.code = itemList.code,
                                                        selectedLanguageCompanyFlag = itemList.flag,
                                                        dropListLanguages(false,'company'),
                                                        errorForm.company.language_id = false">

                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag}/flags`">
                                                    <a>{{ languagesList(itemList.code) }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <!-- TAX NUMBER -->
                                <div class="col-12 col-lg-6">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyTaxNumber")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyTaxNumber.info")}}</span></i>
                                        </span>

                                        <input type="text" class="form" maxlength="80"
                                            v-model="dataMerchant.tax_number"
                                            :class="{error: errorForm.company.tax_number}"
                                            @keydown="errorForm.company.tax_number = false">

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.tax_number'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                    </label>
                                    
                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyTaxNumber")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyTaxNumber.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.tax_number}}
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration" :class="{boxPending: errorForm.company.emailPhoneExists}">
                            <div class="row formBox">
                                
                                <!-- EMAIL COMPANY -->
                                <div class="col-12 col-lg-6">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'"
                                        :class='{error: (errorForm.company.emailPhoneExists || validateEmail.company.regex === false || errorForm.company.email), validated: validateEmail.company.regex}'>
                                        
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyEmail")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyEmail.info")}}</span></i>
                                        </span>

                                        <input type="text" class="form" maxlength="90"
                                            v-model="validateEmail.company.val"
                                            @blur='regexEmail("company")'
                                            @keydown="errorForm.company.email = false">
                                        
                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.email'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                        
                                        <p class="msg-validate-form msg-error anime" v-if='validateEmail.company.regex === false'>
                                            {{getMyLanguage("errors","errors.emailError")}}
                                        </p>

                                        <!-- <p class="msg-validate-form msg-error anime" v-if='errorForm.company.emailPhoneExists'>
                                            {{getMyLanguage("errors","errors.emailRegistered")}}
                                        </p> -->
                                    </label>
                                    
                                    
                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyEmail")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyEmail.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.email}}
                                        </div>
                                    </label>
                                </div>

                                <!-- PHONE COMPANY -->
                                <div class="col-12 col-lg-6">
                                    <div class="form-ddi-phone" v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <label
                                            :class='{validated: validatePhone.company.regex,
                                            error: (errorForm.company.emailPhoneExists || validatePhone.company.regex === false || errorForm.company.phone_number)}'>
                                            
                                            <span class="info">
                                                {{getMyLanguage("Store","merchant.store.lbl.companyPhone")}}
                                                
                                                <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyPhone.info")}}</span></i>
                                            </span>
                                            
                                            <div class="select-ddi short" :class='{disabled: selectedCountryDDICompany.phoneMask == "?"}' v-if="selectedCountryDDICompany.changeMask">
                                                                                                
                                                <div class="autocomplete no-icon formResidenceCountry"
                                                    :class="{opened: dropListOpened.countryPhoneCompany}"
                                                    v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                                    
                                                    <div class="openAutocomplete"
                                                        v-if="!listPhoneCountryCompanyStatus"
                                                        @click="dropListCountry(true,'phoneCompany')"></div>
                                                    
                                                    <div class="closeAutocomplete closeDropListCountry"
                                                        v-if="listPhoneCountryCompanyStatus"
                                                        @click="dropListCountry(false,'phoneCompany')"></div>

                                                    <label>
                                                        <img class="flag-selected"
                                                            :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedPhoneCountryFlagCompany}/flags`"
                                                            v-if="selectedPhoneCountryFlagCompany != false">
                                                    </label>

                                                    <div class="cont-autocomplete" v-if="listPhoneCountryCompanyStatus">
                                                        <ul id="drop-list">
                                                            <li v-for="(itemPhoneCountryCompany,index) in $store.state.allCountries" :key="index"
                                                                @click="dataMerchant.phone_country_id = itemPhoneCountryCompany.id,
                                                                    changeMask('phoneCompany'),
                                                                    selectedPhoneCountryFlagCompany = itemPhoneCountryCompany.Flag,
                                                                    selectedCountryDDICompany.countrySelected = true,
                                                                    selectedCountryDDICompany.active = 'active',
                                                                    selectedCountryDDICompany.phoneMask = itemPhoneCountryCompany.PhoneMask,
                                                                    selectedCountryDDICompany.ddi = itemPhoneCountryCompany.DDI,
                                                                    selectedCountryDDICompany.flag = itemPhoneCountryCompany.Flag,
                                                                    selectedCountryCompany.isocode = itemPhoneCountryCompany.IsoCode,
                                                                    dropListCountry(false,'phoneCompany'),
                                                                    errorForm.company.phone_country_id = false">

                                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemPhoneCountryCompany.Flag}/flags`">
                                                                <a>{{ countryTranslate(itemPhoneCountryCompany.IsoCode) }}</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                                <div class="selected-ddi">
                                                    <div class="flag">
                                                        <img
                                                            :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDICompany.flag}/flags`"
                                                            v-if="selectedCountryDDICompany.flag != ''">
                                                    </div>
                                                    
                                                    <span class="ddi" v-if="selectedCountryDDICompany.phoneMask != '?'">
                                                        +{{selectedCountryDDICompany.ddi}}
                                                    </span>
                                                </div>
                                                
                                                <input type="text" class="form" id="phoneNumberCompany" inputmode="numeric" maxlength="25"
                                                    v-if="selectedCountryDDICompany.phoneMask != '?'"
                                                    v-model="validatePhone.company.val"
                                                    v-mask="selectedCountryDDICompany.phoneMask"
                                                    :placeholder="selectedCountryDDICompany.phoneMask"
                                                    @keydown="errorForm.company.phone_number = false"
                                                    @blur='regexPhone("company")'>
                                            </div>

                                            <div class="load" v-if="!selectedCountryDDICompany.changeMask"></div>

                                            <p class="msg-validate-form msg-error anime" v-if='errorForm.company.phone_number'>
                                                {{getMyLanguage("errors","errors.required")}}
                                            </p>

                                            <p class="msg-validate-form msg-error anime" v-if='validatePhone.company.regex === false'>
                                                {{getMyLanguage("errors","errors.phoneError")}}
                                            </p>

                                            <!-- <p class="msg-validate-form msg-error anime" v-if='errorForm.company.emailPhoneExists'>
                                                {{getMyLanguage("errors","errors.phoneRegistered")}}
                                            </p> -->
                                        </label>
                                    </div>


                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyPhone")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyPhone.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled form-phone-disabled">
                                            <img class="flag-selected" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedPhoneCountryFlagCompany}/flags`">

                                            <span>+{{dataMerchant.phone_country_ddi}} {{dataMerchant.phone_number}}</span>
                                        </div>
                                    </label>

                                    <!-- <div class="form-validate">
                                        <label>
                                            <span class="info">
                                                {{getMyLanguage("Store","merchant.store.lbl.companyPhone")}}
                                                
                                                <i><span>aaaa</span></i>
                                            </span>

                                            <img class="flag" src="https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/ef8d7ffc-7765-4aa5-75be-3c8758b7ff00/flags">
                                            
                                            <div class="phone Pending">351315135151</div>
                                        </label>

                                        <div class="validate-phone" @click="modalPhoneValidate = true">
                                            {{getMyLanguage("Store","merchant.store.lbl.phone.validate.btn")}}
                                        </div>
                                    </div> -->
                                </div>

                                <!-- COMPANY > EMAIL PHONE EXISTS -->
                                <p class="msg-validate-form msg-error email-phone-exists anime" v-if="errorForm.company.emailPhoneExists">
                                    {{getMyLanguage("errors","errors.emailPhoneRegistered")}}
                                </p>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="row formBox">

                                <!-- COUNTRY COMPANY -->
                                <div class="col-12 col-lg-6" v-if="dataLanguage">
                                    <div class="autocomplete has-label no-icon formResidenceCountry"
                                        :class="{opened: dropListOpened.countryCompany}"
                                        v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">

                                        <div class="openAutocomplete"
                                            v-if="!listCountryCompanyStatus"
                                            @click="dropListCountry(true,'company')"></div>
                                        
                                        <div class="closeAutocomplete closeDropListCountry"
                                            v-if="listCountryCompanyStatus"
                                            @click="dropListCountry(false,'company')"></div>

                                        <label>
                                            <img class="flag-selected"
                                                :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagCompany}/flags`"
                                                v-if="selectedCountryFlagCompany != false">

                                            <span class="info">
                                                {{getMyLanguage("Store","merchant.store.lbl.companyCountry")}}
                                                
                                                <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyCountry.info")}}</span></i>
                                            </span>

                                            <input type="text" id="residenceCountryCompany" class="form"
                                                :class="{selected: selectedCountryFlagCompany != false, error: errorForm.company.address.country_id}"
                                                @focus="dropListCountry(true,'company')"
                                                @blur="dropListCountry(false,'company')"
                                                @keydown.esc="dropListCountry(false,'company')"
                                                @keyup="filterListCountryCompany"
                                                :value="countryTranslate(selectedCountryCompany.isocode)">

                                            <p class="msg-validate-form msg-error anime" v-if='errorForm.company.address.country_id'>
                                                {{getMyLanguage("errors","errors.residenceError")}}
                                            </p>
                                        </label>

                                        <div class="cont-autocomplete" v-if="listCountryCompanyStatus">
                                            <ul id="drop-list">
                                                <li v-for="(itemListCompany,index) in $store.state.allCountries" :key="index"
                                                    @click="dataMerchant.address.country_id = itemListCompany.Id,
                                                        selectedCountryFlagCompany = itemListCompany.Flag,
                                                        selectedCountryCompany.isocode = itemListCompany.IsoCode,
                                                        dropListCountry(false,'company'),
                                                        errorForm.company.address.country_id = false">

                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListCompany.Flag}/flags`">
                                                    <a>{{ countryTranslate(itemListCompany.IsoCode) }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.companyCountry")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.companyCountry.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled form-phone-disabled">
                                            <img class="flag-selected" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagCompany}/flags`">

                                            <span>{{ countryTranslate(selectedCountryCompany.isocode) }}</span>
                                        </div>
                                    </label>
                                </div>

                                <!-- STATE -->
                                <div class="col-12 col-lg-6">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.state")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.state.info")}}</span></i>
                                        </span>

                                        <input type="text" class="form"
                                            v-model="dataMerchant.address.state"
                                            :class="{error: errorForm.company.address.state}"
                                            @keydown="errorForm.company.address.state = false">

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.address.state'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                    </label>
                                    
                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.state")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.state.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.address.state}}
                                        </div>
                                    </label>
                                </div>
                                
                                <!-- CITY -->
                                <div class="col-12 col-lg-6">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.city")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.city.info")}}</span></i>
                                        </span>

                                        <input type="text" class="form"
                                            v-model="dataMerchant.address.city"
                                            :class="{error: errorForm.company.address.city}"
                                            @keydown="errorForm.company.address.city = false">

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.address.city'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                    </label>
                                    
                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.city")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.city.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.address.city}}
                                        </div>
                                    </label>
                                </div>

                                <!-- POSTAL CODE -->
                                <div class="col-12 col-lg-6">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.postalCode")}}

                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.postalCode.info")}}</span></i>
                                        </span>

                                        <input type="text" class="form"
                                            v-model="dataMerchant.address.postal_code"
                                            :class="{error: errorForm.company.address.postal_code}"
                                            @keydown="errorForm.company.address.postal_code = false">

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.address.postal_code'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                    </label>
                                    
                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.postalCode")}}

                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.postalCode.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.address.postal_code}}
                                        </div>
                                    </label>
                                </div>

                                <!-- ADDRESS 01 -->
                                <div class="col-12 col-lg-6">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.address1")}}

                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.address1.info")}}</span></i>
                                        </span>

                                        <input type="text" class="form"
                                            v-model="dataMerchant.address.address_1"
                                            :class="{error: errorForm.company.address.address_1}"
                                            @keydown="errorForm.company.address.address_1 = false">

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.address.address_1'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                    </label>
                                    
                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.address1")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.address1.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.address.address_1}}
                                        </div>
                                    </label>
                                </div>
                                
                                <!-- NUMBER -->
                                <div class="col-12 col-lg-2">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.number")}}

                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.number.info")}}</span></i>
                                        </span>

                                        <input type="text" class="form"
                                            v-model="dataMerchant.address.number"
                                            :class="{error: errorForm.company.address.number}"
                                            @keydown="errorForm.company.address.number = false">

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.address.number'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                    </label>
                                    
                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.number")}}

                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.number.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.address.number}}
                                        </div>
                                    </label>
                                </div>
                                
                                <!-- ADDRESS 02 -->
                                <div class="col-12 col-lg-4">
                                    <label v-if="dataMerchant.status == 'None' || dataMerchant.status == 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.address2-company")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.address2.info")}}</span></i>
                                        </span>

                                        <input type="text" class="form"
                                            v-model="dataMerchant.address.address_2"
                                            :class="{error: errorForm.company.address.address_2}"
                                            @keydown="errorForm.company.address.address_2 = false">

                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.company.address.address_2'>
                                            {{getMyLanguage("errors","errors.required")}}
                                        </p>
                                    </label>
                                    
                                    <label v-if="dataMerchant.status != 'None' && dataMerchant.status != 'Pending'">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.address2-company")}}
                                            
                                            <i><span>{{getMyLanguage("Store","merchant.store.lbl.address2.info")}}</span></i>
                                        </span>

                                        <div class="form form-disabled">
                                            {{dataMerchant.address.address_2}}
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration check-concept"
                            :class="{active: dataMerchant.concept, inactive: !dataMerchant.concept}">

                            <div class="form-group-check">
                                <input type="checkbox" id="check-concept" class="form-check"
                                    :checked="dataMerchant.concept" @change="dataMerchant.concept = !dataMerchant.concept">
                                    
                                <label for="check-concept" class="form-check-label">{{ getMyLanguage("Store","merchant.store.check-concept") }}</label>
                            </div>
                        </div>

                        <div class="action" :class="{single: newCompany}">
                            <div class="btn-cancel" v-if="!newCompany" @click="openModalRemoveCompany()">
                                {{ getMyLanguage("Store","merchant.store.btn.remove-company") }}
                            </div>

                            <div class="btn-primary btn-disabled" v-if="!dataMerchant.concept && !flowValidation.load">
                                {{getMyLanguage("Store","merchant.store.btn.saveContinue")}}
                            </div>

                            <div class="btn-primary" v-if="dataMerchant.concept && !flowValidation.load" @click='validateForm("stp01")'>
                                {{getMyLanguage("Store","merchant.store.btn.saveContinue")}}
                            </div>

                            <div class="btn-primary load" v-if="flowValidation.load"></div>
                        </div>
                    </div>
                    


                    <!-- STEP 02 / MERCHANT -->
                    <div class="cont-step manage-partners" v-if='flowStep.stp == "step02" && !flowStep.load'>
                        <h2 class="title-secondary">
                            {{getMyLanguage("Store","merchant.store.partners.title")}}
                        </h2>

                        <div class="box-registration">
                            <h3 class="subtitle">
                                {{getMyLanguage("Store","merchant.store.partners.subtitle")}}
                            </h3>

                            <div class="btn-add" @click="modalViewPartner()">
                                {{getMyLanguage("Store","merchant.store.partners.add-partner")}}
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-12 col-lg-6"
                                    v-for="(listPartner, indexPartners) in dataMerchant.members"
                                    :key="indexPartners">

                                    <div class="kyc-partner"
                                        :class="{active: listPartner.person_kyc, pending: !listPartner.person_kyc}"
                                        @click="modalViewPartner(listPartner, indexPartners)">

                                        <h3>
                                            {{listPartner.full_name}}
                                            <span>{{listPartner.merchant_type_code}}</span>
                                        </h3>

                                        <p v-if="!listPartner.person_kyc">
                                            {{getMyLanguage("Store","merchant.kyc.msg.incomplete")}}
                                        </p>

                                        <p v-if="listPartner.person_kyc">
                                            {{getMyLanguage("Store","merchant.kyc.msg.complete")}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="action">
                            <div class="btn-back" @click='navStep("stp01")'>
                                <span>{{getMyLanguage("Store","merchant.store.btn.back")}}</span>
                            </div>

                            <div class="btn-primary" @click='validateForm("stp02")'>
                                {{getMyLanguage("Store","merchant.store.btn.saveContinue")}}
                            </div>
                        </div>
                    </div>


                    <!-- STEP 03 / KYC / DOCUMENTS -->
                    <div class="cont-step" v-if='flowStep.stp == "step03" && !flowStep.load'>

                        <!-- DOCUMENTS -->
                        <h2 class="title-secondary">
                            {{dataMerchant.legal_name}}
                        </h2>

                        <div class="box-registration box-docs" v-if="!errorsGetDocs.dataCompliance">
                            <h3 class="subtitle">
                                {{getMyLanguage("Store","merchant.store.document-company.title")}}
                            </h3>

                            <p class="obs-subtitle">
                                {{getMyLanguage("Store","merchant.store.document-company.subtitle")}}
                            </p>
                            
                            <div class="row formBox">
                                <div class="col-12 boxes-documents">
                                    <div
                                        v-for="(listDocumentType, indexDocs) in dataMerchant.documents"
                                        :key="indexDocs"
                                        class="box-document"
                                        :class="{mandatory: listDocumentType.account_document_type_mandatory, [listDocumentType.status_extra]:true}"
                                        @click="openModalUploadDocument(listDocumentType.account_document_type_code, listDocumentType.status_extra)">

                                        <h3>
                                            {{ getMyLanguage("Store","merchant.store.document-type." + listDocumentType.account_document_type_code) }}
                                        </h3>
                                        
                                        <p v-if="listDocumentType.account_document_type_mandatory && listDocumentType.status_extra == 'NotLoaded'">
                                            {{ getMyLanguage("Store","merchant.store.document-status.NotLoaded.mandatory") }}
                                        </p>

                                        <p v-else>
                                            {{ getMyLanguage("Store","merchant.store.document-status." + listDocumentType.status_extra) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <div class="status-docs" :class="dataCompliance.backOfficeStatus">
                                <p>{{getMyLanguage("Store","merchant.compliance.msg." + dataCompliance.backOfficeStatus)}}</p>
                            </div> -->

                            <div class="message-docs" v-if="showPending || showSent">
                                <div v-if="showPending" class="box box-pending">
                                    <h3 class="title-secondary">{{ getMyLanguage("Store","merchant.compliance.msg.title-Pending") }}</h3>
                                    <p>{{ getMyLanguage("Store","merchant.compliance.msg.Pending") }}</p>
                                </div>

                                <div class="box box-success" v-else-if="showSent">
                                    <h3 class="title-secondary">{{ getMyLanguage("Store","merchant.compliance.msg.title-waitingAccept") }}</h3>
                                    <p>{{ getMyLanguage("Store","merchant.compliance.msg.Review") }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="box-error" v-if="errorsGetDocs.dataCompliance">
                            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        </div>

                        <div class="action">
                            <div class="btn-back" @click='navStep("stp02")'>
                                <span>{{getMyLanguage("Store","merchant.store.btn.back")}}</span>
                            </div>

                            <div class="btn-primary btnDisabled"
                                v-if="!dataMerchant.summary.company || !dataMerchant.summary.partners || !dataMerchant.summary.documents">
                                
                                {{ getMyLanguage("Store","merchant.store.btn.setup-store") }}
                            </div>
                            
                            <a class="btn-primary"
                                :href="`/${this.flagSelected}/merchant/store`"

                                v-if="dataMerchant.summary.company && dataMerchant.summary.partners && dataMerchant.summary.documents">
                                {{ getMyLanguage("Store","merchant.store.btn.setup-store") }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    


    <!-- MODAL CONCEPT -->
    <div class="modal modal-concept" v-if="modalConcept.modal">
        <div class="modal-cont">
            <div class="modal-close" @click="modalConcept.modal = false"></div>

            <div class="load" v-if="modalConcept.load"></div>

            <div class="modal-concept" v-if="!modalConcept.load">
                <h2 class="title-secondary">
                    {{ getMyLanguage("Store","merchant.store.modal-concept.title") }}
                </h2>
                
                <p class="info" v-html="getMyLanguage('Store','merchant.store.modal-concept.info')"></p>

                <div class="modal-scroll">
                    <p v-html="getMyLanguage('Store','merchant.store.modal-concept.text')"></p>

                    <!-- temp -->
                    <p>Sequi pariatur eaque commodi provident voluptatem impedit, dignissimos natus quasi incidunt ipsum rem! Odio omnis ducimus accusamus, dolore enim velit quis voluptatem ipsum, maxime unde voluptate est perspiciatis, aliquam necessitatibus.</p>
                    <p>Enim tenetur corrupti, blanditiis iure ipsam vero consectetur et deserunt voluptates quaerat fugit inventore dolore dignissimos dolor perspiciatis, eius commodi, facilis numquam. Aliquid dolores similique dolorem ex magni voluptate facilis.</p>
                    <p>Nulla dolor aperiam sapiente praesentium veritatis mollitia officiis magnam aut repellendus maiores accusantium expedita atque eos, ut sint ipsum? Tempora aspernatur dolor eos esse est sint quo harum at illo.</p>
                    <p>Dolore libero facere cumque voluptate, alias ullam deleniti architecto tempore ipsa voluptatem, maiores expedita reprehenderit ut perspiciatis impedit atque! Earum minus maiores deleniti est vitae excepturi ipsa harum aut officiis.</p>
                    <p>Sunt asperiores voluptates similique at maxime placeat aut sed, eius ipsum vitae minus totam dignissimos molestias culpa quisquam exercitationem illum odio delectus ducimus id? Tempora cumque tempore placeat in quaerat?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil explicabo commodi, officia dignissimos consequuntur autem aliquid nam, ex excepturi architecto non aliquam iure deleniti libero, praesentium a. Maiores, at exercitationem.</p>
                    <p>Sequi pariatur eaque commodi provident voluptatem impedit, dignissimos natus quasi incidunt ipsum rem! Odio omnis ducimus accusamus, dolore enim velit quis voluptatem ipsum, maxime unde voluptate est perspiciatis, aliquam necessitatibus.</p>
                    <p>Enim tenetur corrupti, blanditiis iure ipsam vero consectetur et deserunt voluptates quaerat fugit inventore dolore dignissimos dolor perspiciatis, eius commodi, facilis numquam. Aliquid dolores similique dolorem ex magni voluptate facilis.</p>
                    <p>Nulla dolor aperiam sapiente praesentium veritatis mollitia officiis magnam aut repellendus maiores accusantium expedita atque eos, ut sint ipsum? Tempora aspernatur dolor eos esse est sint quo harum at illo.</p>
                    <p>Dolore libero facere cumque voluptate, alias ullam deleniti architecto tempore ipsa voluptatem, maiores expedita reprehenderit ut perspiciatis impedit atque! Earum minus maiores deleniti est vitae excepturi ipsa harum aut officiis.</p>
                    <p>Sunt asperiores voluptates similique at maxime placeat aut sed, eius ipsum vitae minus totam dignissimos molestias culpa quisquam exercitationem illum odio delectus ducimus id? Tempora cumque tempore placeat in quaerat?</p>
                </div>
                
                <div class="action single">
                    <div class="btn-primary" @click="concept()">
                        {{ getMyLanguage("Store","merchant.store.modal-concept.btn-accept") }}
                    </div>
                </div>
            </div>
        </div>
    </div>



    <!-- MODAL PARTNER -->
    <div class="modal modal-partner" id="modalPartner" v-if="modalPartner.modal">
        <div class="modal-cont">
            <div class="modal-close" @click="modalClosePartner()"></div>
            
            <div class="load" v-if="modalPartner.load"></div>

            <div class="modal-scroll" v-if="!modalPartner.load && modalPartner.type != 'del'">

                <!-- PARTNER > CHECK PEP -->
                <div class="box-registration">
                    <div class="row formBox">
                        <div class="col-12">
                            <div class="check-pep">
                                <h3 class="subtitle">
                                    {{getMyLanguage("Store","merchant.store.subtitle.check-pep")}}
                                </h3>

                                <div class="form-group-radio sidebyside">
                                    <input type="radio" name="radioPEP" class="form-radio" id="pepTrue"
                                        v-model="modalPartner.partner.pep"
                                        :value="true"
                                        @click='modalPartner.partner.pep = true'
                                        :checked='modalPartner.partner.pep' >

                                    <label for="pepTrue" class="form-radio-label">
                                        <span>{{getMyLanguage("Store","merchant.store.btn.btn-yes")}}</span>
                                    </label>

                                    <input type="radio" name="radioPEP" class="form-radio" id="pepFalse"
                                        v-model="modalPartner.partner.pep"
                                        :value="false"
                                        @click="modalPartner.partner.pep = false"
                                        :checked='!modalPartner.partner.pep' >

                                    <label for="pepFalse" class="form-radio-label">
                                        <span>{{getMyLanguage("Store","merchant.store.btn.btn-no")}}</span>
                                    </label>
                                </div>

                                <div class="alert alertPEP" v-if="modalPartner.partner.pep">
                                    {{getMyLanguage("Store","merchant.store.alert.check-pep")}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-registration" v-if="!modalPartner.partner.pep">
                    <div class="row">

                        <!-- PARTNER > FULL NAME -->
                        <div class="col-12 col-lg-6">
                            <label class="formFullName" v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{getMyLanguage("Store", "merchant.store.lbl.fullname")}}
                                    
                                    <i><span>{{getMyLanguage("Store", "merchant.store.lbl.fullname.info")}}</span></i>
                                </span>

                                <input type="text" class="form" maxlength="80"
                                    v-model="modalPartner.partner.full_name"
                                    :class="{error: errorForm.partner.full_name}"
                                    @keydown="errorForm.partner.full_name = false">

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.full_name'>
                                    {{getMyLanguage("errors","errors.required")}}
                                </p>
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{getMyLanguage("Store", "merchant.store.lbl.fullname")}}
                                    
                                    <i><span>{{getMyLanguage("Store", "merchant.store.lbl.fullname.info")}}</span></i>
                                </span>

                                <div class="form form-disabled">{{modalPartner.partner.full_name}}</div>
                            </label>
                        </div>
                        
                        <!-- PARTNER > LANGUAGE --> 
                        <div class="col-12 col-lg-6">
                            <div class="autocomplete has-label no-icon formLanguage" :class="{opened: dropListOpened.languageSeller}">
                                <div class="openAutocomplete"
                                    v-if="!listLanguagesSellerStatus"
                                    @click="dropListLanguages(true,'seller')"></div>

                                <div class="closeAutocomplete closeDropListCountry"
                                    v-if="listLanguagesSellerStatus"
                                    @click="dropListLanguages(false,'seller')"></div>

                                <label>
                                    <span class="info">
                                        {{getMyLanguage("Store", "merchant.store.lbl.language")}}
                                        
                                        <i><span>{{getMyLanguage("Store", "merchant.store.lbl.language.info")}}</span></i>
                                    </span>

                                    <img class="flag-selected"
                                        :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageSellerFlag}/flags`"
                                        v-if="selectedLanguageSellerFlag != false">
                                    
                                    <input type="text" id="languageIDmerchant" class="form"
                                        :class="{selected: selectedLanguageSellerFlag != false, error: errorForm.partner.language_id}"
                                        @focus="dropListLanguages(true,'seller')"
                                        @keyup="filterListLanguage('seller')"
                                        @blur="dropListLanguages(false,'seller')"
                                        @keydown.esc="dropListLanguages(false,'seller')"
                                        :value="languagesList(selectedLanguageSeller.code)"
                                        :title='getMyLanguage("Store","merchant.store.lbl.language")'>
                                    
                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.language_id'>
                                        {{getMyLanguage("errors","errors.language")}}
                                    </p>
                                </label>

                                <div class="cont-autocomplete" v-if="listLanguagesSellerStatus">
                                    <ul id="drop-list-merchant">
                                        <li v-for="(itemList,index) in dataLanguage" :key="index"
                                            @click="modalPartner.partner.language_id = itemList.id,
                                                selectedLanguageSeller.code = itemList.code,
                                                selectedLanguageSellerFlag = itemList.flag,
                                                dropListLanguages(false,'seller'),
                                                errorForm.partner.language_id = false">
                                            
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag}/flags`">
                                            <a>{{ languagesList(itemList.code) }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!-- PARTNER > CURRENCY -->
                        <div class="col-12 col-lg-6" v-if="dataCurrency">
                            <div class="autocomplete has-label no-icon formLanguage" :class="{opened:listCurrenciesMerchantStatus}">
                                <div class="openAutocomplete"
                                    v-if="!listCurrenciesMerchantStatus"
                                    @click="dropListCurrencies(true,'merchant')"></div>

                                <div class="closeAutocomplete closeDropListCountry"
                                    v-if="listCurrenciesMerchantStatus"
                                    @click="dropListCurrencies(false,'merchant')"></div>

                                <label>
                                    <span class="info">
                                        {{getMyLanguage("Store","merchant.store.lbl.currency")}}
                                        
                                        <i><span>{{getMyLanguage("Store","merchant.store.lbl.currency.info")}}</span></i>
                                    </span>

                                    <i class="currency-symbol-selected">
                                        {{dataCurrency.filter(cc => cc.quote_asset.id == modalPartner.partner.asset_id)[0].quote_asset.symbol}}
                                    </i>

                                    <input type="text" id="currencyIDmerchant" class="form"
                                        :class="{selected: modalPartner.partner.asset_id, error: errorForm.partner.asset_id}"
                                        @focus="dropListCurrencies(true,'merchant')"
                                        @keyup="filterListCurrency('merchant')"
                                        @blur="dropListCurrencies(false,'merchant')"
                                        @keydown.esc="dropListCurrencies(false,'merchant')"
                                        :value="getMyLanguage('Currency','asset.' + dataCurrency.filter(cc => cc.quote_asset.id == modalPartner.partner.asset_id)[0].quote_asset.short)"
                                        :title='getMyLanguage("Store", "merchant.store.lbl.curency")'>
                                    
                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.type'>
                                        {{getMyLanguage("errors","errors.selectOption")}}
                                    </p>
                                </label>

                                <div class="cont-autocomplete" v-if="listCurrenciesMerchantStatus">
                                    <ul id="drop-list-merchant">
                                        <li v-for="(itemList,indexCurrencyMerchant) in dataCurrency" :key="indexCurrencyMerchant"
                                            @click="modalPartner.partner.asset_id = itemList.quote_asset.id,
                                                dropListCurrencies(false,'merchant'),
                                                errorForm.partner.type = false">

                                            <div class="currency">
                                                <i class="currency-symbol" :class="itemList.quote_asset.symbol">
                                                    {{itemList.quote_asset.symbol}}
                                                </i>

                                                <a>{{getMyLanguage("Currency","asset." + itemList.quote_asset.short)}}</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!-- PARTNER > POSITION COMPANY -->
                        <div class="col-12 col-lg-6">
                            <label>
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.positionCompany")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.positionCompany.info")}}</span></i>
                                </span>

                                <select class="form"
                                    v-model="modalPartner.partner.company_member_type_id"
                                    :class='{error: errorForm.partner.company_member_type_id}'
                                    @change="errorForm.partner.company_member_type_id = false">

                                    <option value="" disabled selected hidden>
                                        {{getMyLanguage("Store","merchant.store.option.select")}}
                                    </option>

                                    <option
                                        v-for="(itemsMerchantType, indexMerchantType) in dataMerchantTypes"
                                        :key="indexMerchantType"
                                        :value="itemsMerchantType.Id">

                                        {{itemsMerchantType.type}}
                                        <!-- {{ getMyLanguage("Store","merchant.store.option.merchant-type." + itemsMerchantType.type) }} -->
                                    </option>
                                </select>

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.company_member_type_id'>
                                    {{getMyLanguage("errors","errors.selectOption")}}
                                </p>
                            </label>
                        </div>
                        
                        <!-- PARTNER > COUNTRY NATIONALITY -->
                        <div class="col-12 col-lg-6">
                            <div class="autocomplete has-label no-icon formResidenceCountry"
                                :class="{opened: listCountryNationalityStatus}"
                                v-if="!modalPartner.partner.person_kyc">

                                <div class="openAutocomplete"
                                    v-if="!listCountryNationalityStatus"
                                    @click="dropListCountry(true,'nationality')"></div>

                                <div class="closeAutocomplete closeDropListCountry"
                                    v-if="listCountryNationalityStatus"
                                    @click="dropListCountry(false,'nationality')"></div>

                                <label>
                                    <img class="flag-selected"
                                        :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagNationality}/flags`"
                                        v-if="selectedCountryFlagNationality != false">
                                    
                                    <span class="info">
                                        {{getMyLanguage("Store","merchant.store.lbl.nationality")}}
                                        
                                        <i><span>{{getMyLanguage("Store","merchant.store.lbl.nationality.info")}}</span></i>
                                    </span>

                                    <input type="text" id="residenceNationalityCountry" class="form"
                                        :class="{selected: selectedCountryFlagNationality != false, error: errorForm.partner.country_id}"
                                        @focus="dropListCountry(true,'nationality')"
                                        @blur="dropListCountry(false,'nationality')"
                                        @keydown.esc="dropListCountry(false,'nationality')"
                                        @keyup="filterListCountryNationality"
                                        :value="countryTranslate(selectedCountryNationality.isocode)">
                                    
                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.country_id'>
                                        {{getMyLanguage("errors","errors.residenceError")}}
                                    </p>
                                </label>

                                <div class="cont-autocomplete" v-if="listCountryNationalityStatus">
                                    <ul id="drop-list-nationality">
                                        <li v-for="(itemListNationality,index) in $store.state.allCountries" :key="index"
                                            @click="modalPartner.partner.country_id = itemListNationality.Id,
                                                selectedCountryFlagNationality = itemListNationality.Flag,
                                                selectedCountryNationality.isocode = itemListNationality.IsoCode,
                                                dropListCountry(false,'nationality'),
                                                errorForm.partner.country_id = false">

                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListNationality.Flag}/flags`">
                                            <a>{{ countryTranslate(itemListNationality.IsoCode) }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <label v-else>
                                <span class="info">
                                        {{getMyLanguage("Store","merchant.store.lbl.nationality")}}
                                        
                                        <i><span>{{getMyLanguage("Store","merchant.store.lbl.nationality.info")}}</span></i>
                                    </span>

                                <div class="form form-disabled form-phone-disabled">
                                    <img class="flag-selected"
                                        :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagNationality}/flags`"
                                        v-if="selectedCountryFlagNationality != false">
                                        {{countryTranslate(selectedCountryNationality.isocode)}}
                                </div>
                            </label>
                        </div>
                        
                        <!-- PARTNER > BIRTHDATE -->
                        <div class="col-12 col-lg-6">
                            <label v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{ getMyLanguage("Store","merchant.store.lbl.birthdate") }}
                                    
                                    <i><span>{{ getMyLanguage("Store","merchant.store.lbl.birthdate.info") }}</span></i>
                                </span>

                                <input type="date" class="form"
                                    v-model="modalPartner.partner.dob"
                                    :class="{error: errorForm.partner.dob}"
                                    @keydown="errorForm.partner.dob = false">

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.dob'>
                                    {{getMyLanguage("errors","errors.required")}}
                                </p>
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{ getMyLanguage("Store", "merchant.store.lbl.birthdate") }}
                                    
                                    <i><span>{{ getMyLanguage("Store","merchant.store.lbl.birthdate.info") }}</span></i>
                                </span>

                                <div class="form form-disabled">{{dateTime(modalPartner.partner.dob)}}</div>
                            </label>
                        </div>

                        <!-- PARTNER > GENDER -->
                        <div class="col-12 col-lg-6">
                            <label v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{ getMyLanguage("Store", "merchant.store.lbl.gender") }}
                                    
                                    <i><span>{{ getMyLanguage("Store", "merchant.store.lbl.gender.info") }}</span></i>
                                </span>

                                <select class="form"
                                    v-model="modalPartner.partner.gender_id"
                                    :class='{error: errorForm.partner.gender_id}'
                                    @change="errorForm.partner.gender_id">

                                    <option value="" disabled selected hidden>
                                        {{getMyLanguage("Store","merchant.store.option.select")}}
                                    </option>

                                    <option v-for="(itemsPersonGender,indexPersonGender) in dataPersonGender" :key="indexPersonGender" :value="itemsPersonGender.Id">
                                        {{getMyLanguage("Store","merchant.store.option.gender." + itemsPersonGender.Value)}}
                                    </option>
                                </select>

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.gender_id'>
                                    {{getMyLanguage("errors","errors.selectOption")}}
                                </p>
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{ getMyLanguage("Store", "merchant.store.lbl.gender") }}
                                    
                                    <i><span>{{ getMyLanguage("Store", "merchant.store.lbl.gender.info") }}</span></i>
                                </span>

                                <div class="form form-disabled">
                                    {{getMyLanguage("Store","merchant.store.option.gender." + dataPersonGender.filter(Gen => Gen.Id == modalPartner.partner.gender_id)[0].Value)}}
                                </div>
                            </label>
                        </div>

                        <!-- PARTNER > TAXNUMBER -->
                        <div class="col-12 col-lg-6">
                            <label v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{ getMyLanguage("Store", "merchant.store.lbl.taxNumber") }}
                                    
                                    <i><span>{{ getMyLanguage("Store", "merchant.store.lbl.taxNumber.info") }}</span></i>
                                </span>

                                <input type="text" class="form"
                                    v-model="modalPartner.partner.tax_number"
                                    :class="{error: errorForm.partner.tax_number}"
                                    @keydown="errorForm.partner.tax_number = false">

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.tax_number'>
                                    {{getMyLanguage("errors","errors.required")}}
                                </p>
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{ getMyLanguage("Store", "merchant.store.lbl.taxNumber") }}
                                    
                                    <i><span>{{ getMyLanguage("Store", "merchant.store.lbl.taxNumber.info") }}</span></i>
                                </span>

                                <div class="form form-disabled">
                                    {{modalPartner.partner.tax_number}}
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="box-registration" :class="{boxPending: errorForm.partner.emailPhoneExists}" v-if="!modalPartner.partner.pep">
                    <div class="row formBox">
                        
                        <!-- PARTNER > EMAIL -->
                        <div class="col-12 col-lg-6">
                            <label v-if="modalPartner.type == 'new'"
                                :class="{validated: (validateEmail.partner.regex && errorForm.partner.emailPhoneExists === false),
                                    error: (validateEmail.partner.regex === false || errorForm.partner.email || errorForm.partner.emailPhoneExists)}">

                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.email")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.email.info")}}</span></i>
                                </span>

                                <input type="text" class="form" maxlength="90"
                                    v-model="validateEmail.partner.val"
                                    @blur='regexEmail("partner")'
                                    @keydown="errorForm.partner.email = false">

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.email'>
                                    {{getMyLanguage("errors","errors.required")}}
                                </p>
                                
                                <p class="msg-validate-form msg-error anime" v-if='validateEmail.partner.regex === false'>
                                    {{getMyLanguage("errors","errors.emailError")}}
                                </p>

                                <!-- <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.emailExists'>
                                    {{getMyLanguage("errors","errors.emailRegistered")}}
                                </p> -->
                            </label>
                            
                            <label v-else>
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.email")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.email.info")}}</span></i>
                                </span>

                                <div class="form form-disabled">{{modalPartner.partner.email}}</div>
                            </label>
                        </div>

                        <!-- PARTNER > PHONE -->
                        <div class="col-12 col-lg-6">
                            <div class="form-ddi-phone" v-if="modalPartner.type == 'new'">
                                <label
                                    :class='{validated: (validatePhone.partner.regex && errorForm.partner.phone_number && errorForm.partner.emailPhoneExists),
                                        error: ( validatePhone.partner.regex === false || errorForm.partner.phone_number || errorForm.partner.emailPhoneExists) }'>

                                    <span class="info">
                                        {{getMyLanguage("Store","merchant.store.lbl.phone")}}
                                        
                                        <i><span>{{getMyLanguage("Store","merchant.store.lbl.phone.info")}}</span></i>
                                    </span>

                                    <div class="select-ddi short" :class='{disabled: selectedCountryDDIPhoneSeller.phoneMask == "?"}' v-if="selectedCountryDDIPhoneSeller.changeMask">
                                        <div class="autocomplete no-icon formResidenceCountry"
                                            v-if="modalPartner.type == 'new'"
                                            :class="{opened: dropListOpened.countryPhoneSeller}">

                                            <div class="openAutocomplete"
                                                v-if="!listCountryPhoneSellerStatus"
                                                @click="dropListCountry(true,'phonePartner')"></div>

                                            <div class="closeAutocomplete closeDropListCountry"
                                                v-if="listCountryPhoneSellerStatus"
                                                @click="dropListCountry(false,'phonePartner')"></div>

                                            <label>
                                                <img class="flag-selected"
                                                    :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagPhoneSeller}/flags`"
                                                    v-if="selectedCountryFlagPhoneSeller != false">
                                            </label>

                                            <div class="cont-autocomplete" v-if="listCountryPhoneSellerStatus">
                                                <ul id="drop-list">

                                                    <li v-for="(itemListPhoneSeller,index) in $store.state.allCountries" :key="index"
                                                        @click="modalPartner.partner.phone_country_id = itemListPhoneSeller.Id,
                                                            changeMask('phonePartner'),
                                                            selectedCountryFlagPhoneSeller = itemListPhoneSeller.Flag,
                                                            selectedCountryDDIPhoneSeller.countrySelected = true,
                                                            selectedCountryDDIPhoneSeller.active = 'active',
                                                            selectedCountryDDIPhoneSeller.phoneMask = itemListPhoneSeller.PhoneMask,
                                                            selectedCountryDDIPhoneSeller.ddi = itemListPhoneSeller.DDI,
                                                            selectedCountryPhoneSeller.isocode = itemListPhoneSeller.IsoCode,
                                                            dropListCountry(false,'phonePartner'),
                                                            errorForm.partner.phone_country_id = false">

                                                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListPhoneSeller.Flag}/flags`">
                                                        <a>{{ countryTranslate(itemListPhoneSeller.IsoCode) }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="selected-ddi">
                                            <div class="flag">
                                                <img
                                                    :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagPhoneSeller}/flags`"
                                                    v-if="selectedCountryFlagPhoneSeller != ''">
                                            </div>

                                            <span class="ddi" v-if="selectedCountryDDIPhoneSeller.phoneMask != '?'">
                                                +{{selectedCountryDDIPhoneSeller.ddi}}
                                            </span>
                                        </div>
                                        
                                        <input type="text" class="form" id="phoneNumberSeller" inputmode="numeric" maxlength="25"
                                            v-if="selectedCountryDDIPhoneSeller.phoneMask != '?'"
                                            v-model="validatePhone.partner.val"
                                            v-mask="selectedCountryDDIPhoneSeller.phoneMask"
                                            :placeholder="selectedCountryDDIPhoneSeller.phoneMask"
                                            @keydown="errorForm.partner.phone_number = false"
                                            @blur='regexPhone("partner")'>
                                    </div>

                                    <div class="load" v-if="!selectedCountryDDIPhoneSeller.changeMask"></div>

                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.phone_number'>
                                        {{getMyLanguage("errors","errors.required")}}
                                    </p>

                                    <p class="msg-validate-form msg-error anime" v-if='validatePhone.partner.regex === false'>
                                        {{getMyLanguage("errors","errors.phoneError")}}
                                    </p>

                                    <!-- <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.phoneExists'>
                                        {{getMyLanguage("errors","errors.phoneRegistered")}}
                                    </p> -->
                                </label>
                            </div>

                            <div class="form-validate" v-if="modalPartner.type != 'new'">
                                <label>
                                    <span class="info">
                                        {{getMyLanguage("Store","merchant.store.lbl.phone")}}

                                        <i><span>{{getMyLanguage("Store","merchant.store.lbl.phone.info")}}</span></i>
                                    </span>
                                    
                                    <img class="flag"
                                        :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagPhoneSeller}/flags`">

                                    <div class="phone" :class="{Pending: !modalPartner.partner.phone_number_verified, Active: modalPartner.partner.phone_number_verified}">
                                        +{{selectedCountryDDIPhoneSeller.ddi}} {{modalPartner.partner.phone_number}}
                                    </div>
                                </label>

                                <div class="validate-phone" @click="modalPhoneValidate = true" v-if="!modalPartner.partner.phone_number_verified">
                                    {{getMyLanguage("Store","merchant.store.lbl.phone.validate.btn")}}
                                </div>
                            </div>
                        </div>

                        <!-- PARTNER > EMAIL PHONE EXISTS -->
                        <p class="msg-validate-form msg-error email-phone-exists anime" v-if='errorForm.partner.emailPhoneExists'>
                            {{getMyLanguage("errors","errors.emailPhoneRegistered")}}
                        </p>
                    </div>
                </div>

                <div class="box-registration" v-if="!modalPartner.partner.pep">
                    <div class="row formBox">

                        <!-- PARTNER > COUNTRY -->
                        <div class="col-12 col-lg-6">
                            <div class="autocomplete has-label no-icon formResidenceCountry"
                                v-if="!modalPartner.partner.person_kyc"
                                :class="{opened: dropListOpened.countrySeller}">

                                <div class="openAutocomplete"
                                    v-if="!listCountrySellerStatus"
                                    @click="dropListCountry(true,'seller')"></div>

                                <div class="closeAutocomplete closeDropListCountry"
                                    v-if="listCountrySellerStatus"
                                    @click="dropListCountry(false,'seller')"></div>

                                <label>
                                    <img class="flag-selected"
                                        :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagSeller}/flags`"
                                        v-if="selectedCountryFlagSeller != false">
                                    
                                    <span class="info">
                                        {{getMyLanguage("Store","merchant.store.lbl.countryResidence")}}
                                        
                                        <i><span>{{getMyLanguage("Store","merchant.store.lbl.countryResidence.info")}}</span></i>
                                    </span>

                                    <input type="text" id="residenceCountrySeller" class="form"
                                        :class="{selected: selectedCountryFlagSeller != false, error: errorForm.partner.address.country_id}"
                                        @focus="dropListCountry(true,'seller')"
                                        @blur="dropListCountry(false,'seller')"
                                        @keydown.esc="dropListCountry(false,'seller')"
                                        @keyup="filterListCountrySeller"
                                        :value="countryTranslate(selectedCountrySeller.isocode)">

                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.address.country_id'>
                                        {{getMyLanguage("errors","errors.residenceError")}}
                                    </p>
                                </label>

                                <div class="cont-autocomplete" v-if="listCountrySellerStatus">
                                    <ul id="drop-list">

                                        <li v-for="(itemListSeller,index) in $store.state.allCountries" :key="index"
                                            @click="modalPartner.partner.address.country_id = itemListSeller.Id,
                                                selectedCountryFlagSeller = itemListSeller.Flag,
                                                selectedCountrySeller.isocode = itemListSeller.IsoCode,
                                                dropListCountry(false,'seller'),
                                                errorForm.partner.address.country_id = false">

                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListSeller.Flag}/flags`">
                                            <a>{{ countryTranslate(itemListSeller.IsoCode) }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <label v-else>
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.countryResidence")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.countryResidence.info")}}</span></i>
                                </span>

                                <div class="form form-disabled form-phone-disabled">
                                    <img class="flag-selected"
                                        :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagSeller}/flags`">

                                    <span>{{countryTranslate(selectedCountrySeller.isocode)}}</span>
                                </div>
                            </label>
                        </div>

                        <!-- PARTNER > STATE -->
                        <div class="col-12 col-lg-6">
                            <label v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.state")}}

                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.state.info")}}</span></i>
                                </span>
                                
                                <input type="text" class="form"
                                    v-model="modalPartner.partner.address.state"
                                    :class="{error: errorForm.partner.address.state}"
                                    @keydown="errorForm.partner.address.state = false">

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.address.state'>
                                    {{getMyLanguage("errors","errors.required")}}
                                </p>
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.state")}}

                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.state.info")}}</span></i>
                                </span>

                                <div class="form form-disabled">
                                    {{modalPartner.partner.address.state}}
                                </div>
                            </label>
                        </div>
                        
                        <!-- PARTNER > CITY -->
                        <div class="col-12 col-lg-6">
                            <label v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.city")}}

                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.city.info")}}</span></i>
                                </span>
                                
                                <input type="text" class="form"
                                    v-model="modalPartner.partner.address.city"
                                    :class="{error: errorForm.partner.address.city}"
                                    @keydown="errorForm.partner.address.city = false">

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.address.city'>
                                    {{getMyLanguage("errors","errors.required")}}
                                </p>
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.city")}}

                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.city.info")}}</span></i>
                                </span>

                                <div class="form form-disabled">
                                    {{modalPartner.partner.address.city}}
                                </div>
                            </label>
                        </div>

                        <!-- PARTNER > POSTAL CODE -->
                        <div class="col-12 col-lg-6">
                            <label v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.postalCode")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.postalCode.info")}}</span></i>
                                </span>
                                
                                <input type="text" class="form"
                                    v-model="modalPartner.partner.address.postal_code"
                                    :class="{error: errorForm.partner.address.postal_code}"
                                    @keydown="errorForm.partner.address.postal_code = false">

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.address.postal_code'>
                                    {{getMyLanguage("errors","errors.required")}}
                                </p>
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.postalCode")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.postalCode.info")}}</span></i>
                                </span>

                                <div class="form form-disabled">
                                    {{modalPartner.partner.address.postal_code}}
                                </div>
                            </label>
                        </div>
                        
                        <!-- PARTNER > ADDRESS 1 -->
                        <div class="col-12 col-lg-6">
                            <label v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.address1")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.address1.info")}}</span></i>
                                </span>
                                
                                <input type="text" class="form"
                                    v-model="modalPartner.partner.address.address_1"
                                    :class="{error: errorForm.partner.address.address_1}"
                                    @keydown="errorForm.partner.address.address_1 = false">

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.address.address_1'>
                                    {{getMyLanguage("errors","errors.required")}}
                                </p>
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.address1")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.address1.info")}}</span></i>
                                </span>

                                <div class="form form-disabled">
                                    {{modalPartner.partner.address.address_1}}
                                </div>
                            </label>
                        </div>
                        
                        <!-- PARTNER > NUMBER -->
                        <div class="col-12 col-lg-2">
                            <label v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.number")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.number.info")}}</span></i>
                                </span>

                                <input type="text" class="form"
                                    v-model="modalPartner.partner.address.number"
                                    :class="{error: errorForm.partner.address.number}"
                                    @keydown="errorForm.partner.address.number = false">

                                <p class="msg-validate-form msg-error anime" v-if='errorForm.partner.address.number'>
                                    {{getMyLanguage("errors","errors.required")}}
                                </p>
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.number")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.number.info")}}</span></i>
                                </span>

                                <div class="form form-disabled">
                                    {{modalPartner.partner.address.number}}
                                </div>
                            </label>
                        </div>
                        
                        <!-- PARTNER > ADDRESS 2 -->
                        <div class="col-12 col-lg-4">
                            <label v-if="!modalPartner.partner.person_kyc">
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.address2")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.address2.info")}}</span></i>
                                </span>

                                <input type="text" class="form" v-model="modalPartner.partner.address.address_2">
                            </label>

                            <label v-else>
                                <span class="info">
                                    {{getMyLanguage("Store","merchant.store.lbl.address2")}}
                                    
                                    <i><span>{{getMyLanguage("Store","merchant.store.lbl.address2.info")}}</span></i>
                                </span>

                                <div class="form form-disabled">
                                    {{modalPartner.partner.address.address_2}}
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="action" v-if="!modalPartner.partner.pep">
                    <!-- regra para permissão de exclusão > ex.: sócio único não pode -->

                    <!-- <div class="btn-cancel" @click="removePartner('start')">
                        {{getMyLanguage("Store","merchant.store.btn.delete")}}
                    </div> -->

                    <div class="btn-primary"
                        :class="{btnDisabled: modalPartner.partner.pep}"
                        v-if="!flowValidation.load"
                        @click="!modalPartner.partner.pep ? validateForm('partner') : null">

                        {{getMyLanguage("Store","merchant.store.btn.save")}}
                    </div>

                    <div class="btn-primary load" v-if="flowValidation.load"></div>
                </div>
            </div>
            


            <!-- REMOVE PARTNER -->
            <div class="modal-scroll modal-remove" v-if="!modalPartner.load && modalPartner.type == 'del'">
                <h2 class="title-secondary">
                    {{getMyLanguage("Store","merchant.store.partners.remove-partner.title")}}
                </h2>

                <div class="box-registration">
                    <h3>
                        {{modalPartner.partner.full_name}}
                    </h3>

                    <p>
                        <strong>
                            {{getMyLanguage("Store","merchant.store.partners.remove-partner.txt-confirm")}}
                        </strong>
                    </p>

                    <div class="action single">
                        <div class="btn-cancel" @click="modalPartner.modal = false">
                            {{getMyLanguage("Store","merchant.store.btn.btn-no")}}
                        </div>

                        <div class="btn-primary" @click="removePartner('confirm')">
                            {{getMyLanguage("Store","merchant.store.btn.btn-yes")}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <!-- MODAL REMOVE COMPANY -->
    <div class="modal modal-remove-company" v-if="modalRemoveCompany.modal">
        <div class="modal-cont">
            <div class="modal-close" @click="modalRemoveCompany.modal = false"></div>

            <div class="load" v-if="modalRemoveCompany.load"></div>

            <div class="modal-scroll modal-remove" v-if="!modalRemoveCompany.load">
                <h2 class="title-secondary">
                    {{ getMyLanguage("Store","merchant.store.btn.remove-company") }}
                </h2>

                <div class="box-registration">
                    <h3>
                        {{dataMerchant.legal_name}}
                    </h3>

                    <p>
                        <strong>{{ getMyLanguage("Store","merchant.store.btn.remove-company.confirm-text") }}</strong>
                    </p>

                    <div class="action single">
                        <div class="btn-cancel" @click="modalRemoveCompany.modal = false">
                            {{getMyLanguage("Store","merchant.store.btn.btn-no")}}
                        </div>

                        <div class="btn-primary" @click="removeCompany()">
                            {{getMyLanguage("Store","merchant.store.btn.btn-yes")}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <!-- MODAL PHONE VALIDATE -->
    <div class="modal" v-if="modalPhoneValidate">
        <div class="modal-cont modalShort">
            <div class="modal-close" @click="modalPhoneValidate = false"></div>
            
            <h2 class="title-secondary">
                {{getMyLanguage("Store","merchant.store.lbl.phone.validate")}}
            </h2>

            <p class="step-validate"
                v-if="!flowSendSMS.success && !errorsFlowSendSMS.flow"
                v-html='getMyLanguage("Store","merchant.store.lbl.phone.validate-step01")'></p>
            
            <div class="load" v-if="flowSendSMS.load"></div>

            <!-- SMS CODE -->
            <div class="sms-code" v-if="!flowSendSMS.success && !errorsFlowSendSMS.flow">                        
                <div class="btn-primary getCode"
                    v-if="!flowSendSMS.smsCodeShow && !flowSendSMS.load"
                    @click="getCodeSend()">

                    {{getMyLanguage("SMS","modal-send-code.btn-generate")}}
                </div>

                <div class="sms-code-cont">
                    <div class="invalid-code" v-if="errorsFlowSendSMS.invalidCode">
                        {{getMyLanguage("Store","merchant.store.lbl.phone.validate-error")}}
                    </div>

                    <div class="sms-confirm"
                        :class="{errorCode: errorsFlowSendSMS.invalidCode}"
                        v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">

                        <input type="text" class="form form-smsCode" id="smsCodeForm"
                            inputmode="numeric"
                            placeholder="______"
                            autocomplete="one-time-code"
                            v-model="codeSMS.code"
                            v-mask="'000000'">

                        <div class="confirm btn-disabled"
                            v-if="codeSMS.code.length < 6">

                            {{getMyLanguage("SMS","modal-send-code.btn-confirm")}}
                        </div>

                        <div class="confirm"
                            v-if="codeSMS.code.length == 6"
                            @click="validateCodeSMS()">

                            {{getMyLanguage("SMS","modal-send-code.btn-confirm")}}
                        </div>
                    </div>
                    
                    <div class="alert"
                        v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">

                        {{getMyLanguage("SMS","modal-send-code.msg-sms")}} +{{$store.state.profile.profile_phone_country_ddi}} {{ maskedPhone($store.state.profile.profile_phone_number) }}
                    </div>
                    <!-- <div class="load" v-if="flowSendSMS.smsCodeShow && flowSendSMS.load"></div> -->
                </div>


                <div class="resend"
                    v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">

                    <div class="btn-resend resend-load" v-if="!flowSendSMS.btnResendSMS">
                        {{getMyLanguage("SMS","modal-send-code.btn-resend-in")}} {{flowSendSMS.countDownResendCode}}s
                    </div>
                    
                    <div class="btn-resend"
                        v-if="flowSendSMS.btnResendSMS"
                        @click="getCodeSend()">
                    
                        {{getMyLanguage("SMS","modal-send-code.btn-resend")}}
                    </div>
                </div>
            </div>


            <!-- BOX SUCCESS -->
            <div class="box-success" v-if="flowSendSMS.success">
                <p v-html='getMyLanguage("Store","merchant.store.lbl.phone.validate-ok")'></p>
            </div>


            <!-- BOX ERROR -->
            <div class="box-error" v-if="errorsFlowSendSMS.flow != false">
                <h3 class="title-secondary">
                    {{ getMyLanguage("box-error", "title-error") }}
                </h3>

                <p>{{ getMyLanguage("box-error","msg-error") }}</p>

                <div class="cod-error">
                    Cód.: {{errorsFlowSendSMS.flow}}
                </div>
            </div>
        </div>
    </div>


    <!-- MODAL DOCUMENT UPLOAD -->
    <div class="modal modal-document-upload" v-if="modalDocument.modal">
        <div class="modal-cont modalShort">
            <div class="modal-close" @click="modalDocument.modal = false, modalDocument.status = ''"></div>

            <div class="modal-scroll">
                <h2 class="title-secondary">
                    {{ getMyLanguage("Store","merchant.store.document-type." + dataSendDoc.accountDocumentTypeCode) }}
                </h2>

                <div class="box-success" v-if="modalDocument.status == 'Accepted'">
                    <p>{{getMyLanguage("Store","merchant.store.document-status.box-success")}}</p>
                </div>

                <div v-if="modalDocument.status != 'Accepted'">
                    <label for="LblUploadFile" class="upload">
                        <span v-if="!uploadFileDocument.selected">
                            {{getMyLanguage("Store","merchant.store.document-status.btn-upload")}}
                            
                            <small>
                                ({{getMyLanguage("Store","merchant.store.document-status.btn-upload.formats")}})
                            </small>
                        </span>

                        <em v-if="!uploadFileDocument.isImage" :class='uploadFileDocument.selected'>
                            <span class="send" v-if="!uploadFileDocument.selected">
                                {{getMyLanguage("Store","merchant.store.document-status.msg.file-select")}}
                            </span>

                            <span class="selected" v-if="uploadFileDocument.selected">
                                {{getMyLanguage("Store","merchant.store.document-status.msg.file-selected")}}
                            </span>
                        </em>

                        <input type="file" id="LblUploadFile" ref="fileDocument" @change="uploadDocument()">
                        
                        <div class="preview-image" v-if="uploadFileDocument.isImage">
                            <div>
                                <img :src="uploadFileDocument.previewURLImage" v-if="uploadFileDocument.previewURLImage" />
                            </div>
                        </div>
                    </label>

                    <div class="resume-file" v-if="uploadFileDocument.resumeFile">
                        <p>
                            <strong>
                                {{getMyLanguage("Store","merchant.store.document-status.resume-file.file")}}
                            </strong>

                            {{ uploadFileDocument.File.name }}
                        </p>

                        <p>
                            <strong>
                                {{getMyLanguage("Store","merchant.store.document-status.resume-file.type")}}
                            </strong>

                            {{ uploadFileDocument.File.type }}
                        </p>

                        <p>
                            <strong>
                                {{getMyLanguage("Store","merchant.store.document-status.resume-file.size")}}
                            </strong>

                            {{ formatFileSize(uploadFileDocument.File ? uploadFileDocument.File.size : 0) }}
                        </p>
                    </div>

                    <div class="observations-upload noIcon">
                        <label>
                            <span>
                                {{getMyLanguage("Store","merchant.store.document-status.upload-obs")}}
                            </span>

                            <span class="cont">{{dataSendDoc.Observations.length}}/200</span>
                        </label>

                        <textarea class="form" maxlength="200"
                            v-model="dataSendDoc.Observations"
                            :placeholder='getMyLanguage("Store","merchant.store.document-status.upload-obs.placeholder")'></textarea>
                    </div>

                    <div class="box-error" v-if="errorsPostDocs.upload != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{getMyLanguage("Store","merchant.store.document-status.upload-error")}}</p>
                    </div>

                    <div class="bt-upload">
                        <div class="btn-primary btn-disabled" v-if='uploadFileDocument.selected != "fileSelected" || dataSendDoc.accountDocumentTypeCode === ""'>
                            {{getMyLanguage("Store","merchant.store.document-status.send-file")}}
                        </div>

                        <div class="btn-primary"
                            @click="uploadDoc()"
                            v-if='uploadFileDocument.selected == "fileSelected"
                                && !errorsPostDocs.upload
                                && !uploadFileDocument.loadUpload
                                && dataSendDoc.accountDocumentTypeCode != ""'>

                            {{getMyLanguage("Store","merchant.store.document-status.send-file")}}
                        </div>
                        
                        <div class="btn-primary load" v-if="uploadFileDocument.loadUpload"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    const $ = require('jquery')
    window.$ = $

    $(document).on("click",".form-ddi-phone", function(){
        $(".form-ddi-phone").removeClass("active")
        $(this).addClass("active")
    })
    
    $(document).on("blur",".form-ddi-phone", function(){
        $(".form-ddi-phone").removeClass("active")
    })

    import Header from "@/components/Essentials/Header.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";

    import customScriptsSaves from "./script-saves.js";
    import customScriptsGeneral from "./script-company-general.js";
    import customScriptsValidations from "./script-validations.js";
    import customScriptsDocuments from "./script-documents.js";

    // import apiMerchant from '@/config/apiMerchant.js'

    export default {
        components: {
            Header,
            Breadcrumb
        },

        data(){
            return Object.assign(
                {},
                customScriptsSaves.data.call(this),
                customScriptsGeneral.data.call(this),
                customScriptsValidations.data.call(this),
                customScriptsDocuments.data.call(this)
            );
        },
        
        async mounted(){
            await customScriptsGeneral.mounted.call(this), customScriptsDocuments.mounted.call(this);
        },

        methods: {
            ...customScriptsGeneral.methods,
            ...customScriptsDocuments.methods,
            ...customScriptsValidations.methods,
            ...customScriptsSaves.methods,
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                deep: true,
                handler() {
                    this.languageSelected();
                    this.orderLangs()
                    this.ordercountries()
                },
                immediate: true
            },

            '$store.state': {
                deep: true,
                handler(newData) {
                    if (
                        newData.allCountries !== undefined && newData.allCountries !== null &&
                        newData.allLanguages !== undefined && newData.allLanguages !== null &&
                        newData.allCurrencies !== undefined && newData.allCurrencies !== null
                    ) {
                        this.dataCountry = newData.allCountries;
                        this.dataLanguage = newData.allLanguages;
                        this.dataCurrency = newData.allCurrencies;
                        this.checkStores();
                    }
                },
                immediate: true
            }
        },

        created() {
            if ( this.$store.state.statusLogin ){
                if ( this.$route.params.companyReference == "0" ){
                    document.title = `${this.getMyLanguage("Store","merchant.store.partners.add-company")} | DNE Market`
                } else {
                    document.title = `${localStorage.getItem("storeName")} | DNE Market`
                }
            }

            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="@/assets/scss/_merchant-store-company.scss" lang="scss" scoped />