<template>
    <div class="load" v-if="load"></div>

    <div class="container-fluid" v-else>
        <div class="topics scrollHorizontalMouse" ref="scrollContainer" @mousedown="startDrag">
            <div class="cont" ref="scrollContent">
                 <a :href="item.url" class="topic" v-for="(item, index) in dataTopics" :key="index">
                    <figure class="icon">
                        <img :src="item.icon">
                    </figure>
                    <h4>{{item.name}}</h4>
                </a>
            </div>
        </div>
    </div>
</template>


<script>
    import apiMarket from '@/config/apiMarket.js'
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        data (){
            return {
                flagSelected: "en",

                load: true,
                dataTopics: [],

                isDragging: false,
                startX: 0,
                scrollLeft: 0,
            }
        },

        async mounted() {
            apiMarket.get(`api/v1/category/topics`)
            .then(response => {
                this.dataTopics = response.data
                this.load = false
            })
            .catch(error => {
                console.error(error)
                this.load = false
            })
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            startDrag(event) {
                this.isDragging = true;
                this.startX = event.pageX - this.$refs.scrollContainer.offsetLeft;
                this.scrollLeft = this.$refs.scrollContainer.scrollLeft;

                document.addEventListener('mousemove', this.handleDrag);
                document.addEventListener('mouseup', this.stopDrag);
            },

            handleDrag(event) {
                if (!this.isDragging) return;
                event.preventDefault();

                const x = event.pageX - this.$refs.scrollContainer.offsetLeft;
                const walk = (x - this.startX) * 2;
                this.$refs.scrollContainer.scrollLeft = this.scrollLeft - walk;
            },

            stopDrag() {
                this.isDragging = false;
                document.removeEventListener('mousemove', this.handleDrag);
                document.removeEventListener('mouseup', this.stopDrag);
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';

    .container-fluid{
        max-width: 1400px;
        
        @media (max-width: 992px){
            padding: 1rem .5rem .5rem;
        }

        @media (min-width: 992px){
            padding: 1rem 30px;
        }
    }

    .topics{
        overflow-x: auto;
        max-width: 1400px;
        margin: 0 auto;
        padding-bottom: .5rem;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @include scrollbars(8px, $color-secondary, #ddd);
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
        }            
        &::-webkit-scrollbar-track {
            border-radius: 8px;
        }

        .cont{
            display: flex;
            flex-wrap: nowrap;

            .topic{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: calc(100%/9);
                padding: 5px;

                @media (max-width: 576px){
                    min-width: 21vw;
                }
                
                @media (min-width: 576px){
                    min-width: 100px;
                }

                @media (min-width: 992px){
                    cursor: pointer;

                    &:hover{
                        .icon{
                            img{
                                transform: scale(1.15);
                            }
                        }
                    }
                }


                .icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5px;

                    img{
                        transition: 1s;
                        
                        @media (max-width: 576px){
                            width: 20px;
                            height: 20px;
                        }
                        
                        @media (min-width: 576px){
                            width: 34px;
                            height: 34px;
                        }
                    }
                }

                h4{
                    font-family: $font-secondary-extralight ;
                    color: $color-primary;
                    text-align: center;
                    margin: 0;
                    max-height: 30px;
                    overflow: hidden;
                    // text-shadow: 0px 4px 6px rgba(0,0,0,0.5);
                    
                    @media (max-width: 576px){
                        font-size: 11px;
                        line-height: 11px;
                    }
                    
                    @media (min-width: 576px){
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }

    }
</style>