const $ = require('jquery')
window.$ = $

import apiPay from '@/config/apiPay.js'
import apiMerchant from '@/config/apiMerchant.js'

export default {
    data() {
        return {
            VUE_APP_PAY_URL: process.env.VUE_APP_PAY_URL,

            modalPlugin: {
                show: false,
                load: false,
                currency: [],
                formattedPrice: "",

                typePlugin: {
                    name: "Plugin",
                    code: "plugin"
                },

                code: {
                    asset_id: "db348135-d40e-4803-b1b9-50876356f419",
                    show: false,
                    copying: false
                },

                iframe: {
                    show: false,
                    url: ""
                },

                urlPay: {
                    load: false,
                    url: "",
                    copying: false
                }
            },

            pluginCode: {
                external_id: "234532453245",
                campaign_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                store_id: "",
                instrument: "EURDNE",
                language: "pt",
                country: "PRT",
                items: [
                    {
                        external_id: "123412341234",
                        campaign_id: "00000000-0000-0000-0000-000000000000",
                        product_variant_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        name: "",
                        description: "",
                        image_url: "",
                        // name: "Tapete",
                        // description: "Alinhado White, Nogueira Mate, Chaise Esquerdo",
                        // image_url: "https://1937169732.rsc.cdn77.org/temp/1733151616_1dbd7038caa17afea22ea8335514fc4b.jpg",
                        price: ""
                    }
                ]
            }
        }
    },

    async mounted() {
        // LOAD ASSETS
        const assetFiat = await apiPay.get("/api/v1/pay/instrument/prices?trade_asset_type=fiat&quote_asset_short=DNE&quote_asset_type=crypto")
        this.modalPlugin.currency = assetFiat.data

        this.pluginCode.instrument = "EURDNE"
    },

    methods: {
        openModal(){
            this.modalPlugin.show = true
            this.modalPlugin.load = true

            setTimeout(() => {
                this.modalPlugin.load = false
            }, 1500);
        },

        closeModal(){
            this.modalPlugin.show = false
        },

        changeTypePlugin(value){
            if ( value == "plugin" ){
                this.modalPlugin.typePlugin.code = value
                this.modalPlugin.typePlugin.name = 'Plugin'
            }
            
            if ( value == "plugin-info" ){
                this.modalPlugin.typePlugin.code = value
                this.modalPlugin.typePlugin.name = 'PluginInfo'
            }
        },

        updateUrlIframe(){
            this.modalPlugin.iframe.show = false
            this.modalPlugin.iframe.url = ""

            if ( this.modalPlugin.formattedPrice ){
                this.convertPrice()
            }

            setTimeout(() => {
                const itemsEncoded = encodeURIComponent(JSON.stringify(this.pluginCode.items))
                //this.modalPlugin.iframe.url = `${this.VUE_APP_PAY_URL}plugin?${window.btoa(JSON.stringify(this.pluginCode))}`

                this.modalPlugin.iframe.url = `${this.VUE_APP_PAY_URL}${this.modalPlugin.typePlugin.code}?external_id=${this.pluginCode.external_id}&campaign_id=${this.pluginCode.campaign_id}&store_id=${this.pluginCode.store_id}&instrument=${this.pluginCode.instrument}&country=${this.pluginCode.country}&items=${itemsEncoded}`

                this.modalPlugin.iframe.show = true
                
                this.validateCode()
            }, 100);
        },

        convertPrice() {
            const numericValue = parseFloat(this.modalPlugin.formattedPrice.replace(',', '.').replace(/[^0-9.]/g, ''));

            if (!isNaN(numericValue)) {
                this.pluginCode.items[0].price = numericValue;
                this.modalPlugin.formattedPrice = numericValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            } else {
                this.pluginCode.items[0].price = null;
            }
        },

        validateCode(){
            if ( this.pluginCode.items[0].name && this.pluginCode.instrument && this.pluginCode.items[0].price ){
                this.modalPlugin.code.show = true

                setTimeout(() => {
                    this.generateCode()
                }, 100);
            }
        },

        generateCode() {
            const headerCode = `
<!-- START PLUGIN DNE PAY -->
<div id="${this.modalPlugin.typePlugin.code}-dne-pay-modal" style="display: none;">
    <div class="box-iframe">
        <iframe id="${this.modalPlugin.typePlugin.code}-dne-pay-iframe" frameborder="0"></iframe>
        <div id="${this.modalPlugin.typePlugin.code}-dne-pay-close"></div>
    </div>
</div>

<div id="${this.modalPlugin.typePlugin.code}-dne-pay-button" style="display: none;">
    <img src="https://plugin.dnepay.com/dne-pay.svg" alt="DNE Pay">
    <span id="${this.modalPlugin.typePlugin.code}-dne-pay-text"></span>
</div>

<script>
(function load${this.modalPlugin.typePlugin.name}() {
const version${this.modalPlugin.typePlugin.name} = Math.floor(Math.random() * 99999);

const cssLink${this.modalPlugin.typePlugin.name} = document.createElement('link');
cssLink${this.modalPlugin.typePlugin.name}.rel = 'stylesheet';
cssLink${this.modalPlugin.typePlugin.name}.href = \`https://plugin.dnepay.com/dne-pay-${this.modalPlugin.typePlugin.code}.v.1.0.css?v=\${version${this.modalPlugin.typePlugin.name}}\`;
document.head.appendChild(cssLink${this.modalPlugin.typePlugin.name});

const script${this.modalPlugin.typePlugin.name} = document.createElement('script');
script${this.modalPlugin.typePlugin.name}.src = \`https://plugin.dnepay.com/dne-pay-${this.modalPlugin.typePlugin.code}.v.1.0.js?v=\${version${this.modalPlugin.typePlugin.name}}\`;
document.body.appendChild(script${this.modalPlugin.typePlugin.name});

window.${this.modalPlugin.typePlugin.name}Config =
`.trim();

            const footerCode = `
})();
</` + `script>`.trim();

            $("#headerCode").text(headerCode)
            $("#footerCode").text(footerCode)
        },

        copyCodePlugin() {
            this.modalPlugin.code.copying = true

            const code = document.querySelector(".plugin-code .code").textContent;
            navigator.clipboard.writeText(code).then(
                () => this.$toast.success("Código copiado"),
                (err) => this.$toast.error("Erro ao copiar: " + err)
            );

            setTimeout(() => {
                this.modalPlugin.code.copying = false
            }, 500);
        },


        // LNK PAYMENT
        generateLnkPayment(){
            this.modalPlugin.urlPay.load = true

            apiMerchant.post("/api/v2/plugin/link", this.pluginCode)
            .then(response => {
                this.modalPlugin.urlPay.url = response.data.url

                setTimeout(() => {
                    this.modalPlugin.urlPay.load = false
                }, 1000);
            })
            .catch(error => {
                console.error(error)

                setTimeout(() => {
                    this.$toast.error("Erro ao gerar o link de pagamento, por favor tente mais tarde.")
                    this.modalPlugin.urlPay.load = false
                }, 1500);
            })
        },


        selectTypeCampaign(type){
            this.modalPlugin.code.type_id = type.id
            this.modalPlugin.code.type = type.type
            this.modalPlugin.code.type_extra = type.type_extra
        },
    }
}