<template>
    <div class="menu">
        
        <!-- MENUS -->
        <ul id="nav-dne" v-if="showContent && ( dataSource == 'menus' || dataSource == 'search' )">
            <li v-for="(menu, index) in menus" :key="index">
                <a :href="`/${flagSelected}/${menu.slug}`">
                    <span>{{menu.name}}</span>
                </a>
            </li>
        </ul>
        
        <!-- ACCOUNT -->
        <ul id="nav-dne" v-if="showContent && ( dataSource == 'account' )">
            <li v-for="(menu, index) in menuAccount" :key="index">
                <a :href="`/${flagSelected}/${menu.slug}`" :class="{active: isActive(menu), [menu.name]: true}">
                    <span>{{ getMyLanguage("Account","menu." + menu.name) }}</span>
                </a>
            </li>
        </ul>
        
        <!-- MERCHANT -->
        <ul id="nav-dne" v-if="showContent && dataSource == 'merchant'">
            <li v-for="(menu, index) in menuMerchant" :key="index">
                <a :href="`/${flagSelected}/${menu.slug}`" :class="{active: isActive(menu), [menu.name]: true}" v-if="menu.active && menu.active != '0'">
                    <span>{{ getMyLanguage("Merchant","menu." + menu.name) }}</span>
                </a>

                <a :class="['inactive', menu.name]" v-if="!menu.active || menu.active == '0'">
                    <span>{{ getMyLanguage("Merchant","menu." + menu.name) }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {
                type: String
            }
        },

        data(){
            return {
                showContent: false,
                flagSelected: "en",

                storeReference: localStorage.getItem("storeReference"),
                companyReference: localStorage.getItem("companyReference"),

                menus: [
                    { slug: "brand/1000049440/apple", name: "Super ofertas" },
                    { slug: "brand/1000049440/apple", name: "Novidades" },
                    { slug: "brand/1000049440/apple", name: "3 itens por 10 DNE" },
                    { slug: "brand/1000049440/apple", name: "Frete grátis" }
                ],

                menuAccount: [
                    { slug: "account/purchases", name: "purchases" },
                    { slug: "account/purchases/returns", name: "returns" },
                    { slug: "account/purchases#my-account", name: "my-account" },
                    { slug: "account/purchases#vouchers", name: "vouchers" },
                    { slug: "account/purchases#ratings", name: "ratings" }
                ],

                menuMerchant: []
            }
        },

        async mounted(){
            this.showContent = true

            this.menuMerchant = [
                { slug: 'merchant/dashboard', name: 'merchant-dashboard', active: this.storeReference },
                { slug: 'merchant/sales', name: 'merchant-sales', active: this.storeReference },
                { slug: 'merchant/plugin-dne-pay', name: 'merchant-plugin-dne-pay', active: this.storeReference },
                { slug: 'merchant/returns', name: 'merchant-returns', active: this.storeReference },
                { slug: 'merchant/products', name: 'merchant-products', active: this.storeReference },
                { slug: 'merchant/campaigns', name: 'merchant-campaigns', active: this.storeReference },
                { slug: 'merchant/store', name: 'merchant-store', active: this.storeReference },
                { slug: `merchant/company/${this.companyReference}`, name: 'merchant-company', active: this.companyReference }
            ]
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            isActive(menu) {
                const slugCheck = `/${this.flagSelected}/${menu.slug}`
                return slugCheck == window.location.pathname;
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>



<style lang="scss">
    @import '@/assets/scss/_colors.scss';

    header{
        .col-header{
            #nav-dne{
                @media (min-width: 1280px){
                    height: 40px;
                    display: flex;
                    flex-wrap: wrap;
                    overflow-y: hidden;
                }
        
                @media(max-width: 1280px){
                    display: none
                }
        
                li{
                    padding: 0 3px;

                    &.lnk-become-seller{
                        @media (min-width: 1280px){
                            display: none;
                        }
                    }
        
                    &.has-child{
                        &:hover{
                            .submenu{
                                display: flex !important;
                            }
                        }
                    }
        
                    a{
                        font-size: 14px;
                        color: #555;
                        line-height: 40px;
                        display: flex;
                        padding: 0 10px;
                        white-space: nowrap;
                        cursor: pointer;
        
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
        
                        @media (min-width: 992px){
                            border-radius: 12px;
                            
                            &:hover, &.active{
                                background: #EDEDED;
                                padding: 0 10px;
                            }
                        }

                        &.inactive{
                            filter: blur(2px);
                        }
                    }
        
                    .submenu{
                        display: none;
                        
                        @media (min-width: 1200px){
                            position: absolute;
                            top: 100%;
                            left: 0;
                            padding: 30px 10px;
                            width: 900px;
                            justify-content: center;
                            background: $color-text-tertiary;
                        }
                    
                        .cont{
                            @media (min-width: 1200px){
                                max-width: 1100px;
                                display: flex;
                                justify-content: center;
                            }
                    
                            ul{
                                @media (min-width: 1200px){
                                    width: 700px;
                                }
                            }
                        
                            .banner-submenu{
                                @media (min-width: 1200px){
                                    width: 360px;
                                }
                            }
                        }
                    }
                }
            }
        
        
        
            // MENU MOBILE OPENED
            .menuMobileOpened{
                left: 0;
        
                &:after{
                    opacity: 1;
                }
        
                .closeMenuMobile{
                    opacity: 1;
                    right: 0;
                    transition: .6s;
                    transition-delay: .6s;
                }
        
                #nav-dne{
                    left: 0;
                    transition: .6s;
                }
            }


            // MERCHANT && ACCOUNT
            &.headerMerchant, &.headerAccount{
                #nav-dne{
                    li{
                        a{
                            @media (max-width: 1200px){
                                padding: 0 10px;
                                line-height: 40px;
                            }

                            @media (min-width: 1200px){
                                position: relative;
                                flex-direction: column;
                                align-items: center;
                                line-height: 20px;
                                padding: 3px 6px;

                                &:after{
                                    content: '';
                                    display: block;
                                    width: calc(50% - 16px);
                                    height: 2px;
                                    position: absolute;
                                    bottom: 2px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    background: transparent;
                                    transition: .6s;
                                }
                                
                                &:hover{
                                    background: none;
                                    
                                    &:after{
                                        width: calc(100% - 16px);
                                        background: #ddd;
                                    }
                                }
                            }

                            @media (min-width: 1300px){
                                padding: 3px 8px;
                            }

                            &.active{
                                background: none;

                                &:after{
                                    width: calc(100% - 16px);
                                    background: #aaa;
                                }
                            }
                            
                            &:before{
                                content: '';
                                display: block;

                                @media (max-width: 1200px){
                                    width: 30px;
                                    height: 50px;
                                    background-size: 20px auto !important;
                                    margin-right: 10px;
                                }

                                @media (min-width: 1200px){
                                    width: 16px;
                                    height: 16px;
                                    background-size: 100% auto !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    body.headerFixed{
        header{
            .col-header{
                #nav-dne{
                    li{
                        a{
                            height: 100%;
                            justify-content: center;

                            &:before{
                                opacity: 0;
                                height: 0;
                                animation: hideIconBefore .7s alternate;
                            }
                            
                            &:hover{
                                &:before{
                                    display: none;
                                }
                            }
                        }

                        @keyframes hideIconBefore {
                            0% {opacity: 1;}
                            50% {opacity: 0;}
                            90% {height: 0;}
                            100%{content: initial;}
                        }
                    }
                }
            }
        }
    }


    // SIDEBAR
    .sidebar{
        .sidebar-cont{
            #nav-dne{
                width: 100%;
                margin-bottom: 2rem;

                li{
                    background: $color-text-tertiary;
                    padding: 0 15px;

                    a{
                        font-size: 14px;
                        color: $color-primary;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        padding: 0 26px 0 0;
                        white-space: nowrap;
                        border-bottom: 1px solid $color-tertiary;
                        position: relative;

                        &:before{
                            content: '';
                            display: block;
                            margin-right: 10px;
                            width: 30px;
                            height: 30px;
                            flex-shrink: 0;
                            border-radius: 50%;
                            background: #f5f5f5;
                            background-size: auto 18px !important;
                            border: 1px solid #eee;
                        }

                        &:after{
                            content: '';
                            display: block;
                            width: 8px;
                            height: 12px;
                            background: url('~@/assets/images/icons/arrow-btn-menu-mobile.svg') no-repeat center;
                            background-size: auto 100%;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
            
                    &.purchase-token{
                        padding-bottom: 20px;

                        a{
                            background: $color-secondary;
                            color: $color-text-tertiary;
                            border-radius: 12px;
                            justify-content: center;

                            &:before, &:after{
                                display: none;
                            }
                        }
                    }

                    &:first-child{
                        padding-top: 20px;
                        border-radius: 12px 12px 0 0;
                    }

                    &:last-child{
                        padding-bottom: 20px;
                        border-radius: 0 0 12px 12px;

                        a{
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }


    // ICONS
    header .col-header, .sidebar .sidebar-cont{
        #nav-dne{
            li{
                a{
                    
                    // MERCHANT
                    &.merchant-dashboard{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-dashboard.svg') no-repeat center;
                        }
                    }

                    &.merchant-sales{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-sales.svg') no-repeat center;
                        }
                    }
                    
                    &.merchant-plugin-dne-pay{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-plugin-dne.svg') no-repeat center;
                        }
                    }
                    
                    &.merchant-returns{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-returns.svg') no-repeat center;
                        }
                    }

                    &.merchant-products{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-products.svg') no-repeat center;
                        }
                    }

                    &.merchant-campaigns{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-campaigns.svg') no-repeat center;
                        }
                    }

                    &.merchant-track-orders{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-track-orders.svg') no-repeat center;
                        }
                    }

                    &.merchant-store{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-manage-store.svg') no-repeat center;
                        }
                    }
                    
                    &.merchant-company{
                        &:before{
                            background: url('~@/assets/images/icons/menu-manager-manage-company.svg') no-repeat center;
                        }
                    }


                    // ACCOUNT
                    &.purchases{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-purchases.svg') no-repeat center;
                        }
                    }

                    &.returns{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-returns.svg') no-repeat center;
                        }
                    }

                    &.my-account{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-my-account.svg') no-repeat center;
                        }
                    }

                    &.vouchers{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-vouchers.svg') no-repeat center;
                        }
                    }

                    &.ratings{
                        &:before{
                            background: url('~@/assets/images/icons/menu-account-ratings.svg') no-repeat center;
                        }
                    }
                }
            }
        }
    }
</style>