<template>
    <div class="legalPolicies">
        <div class="container-fluid">
            <h2 class="title-secondary">
                {{ getMyLanguage("LegalPolicies", "legalPolicies.title") }}
            </h2>

            <div class="row">
                <div class="col-6 col-lg-3">
                    <a :href="`/${this.flagSelected}/terms-and-conditions`" class="btn-legalPolicies">
                        <img src="~@/assets/images/icons/legalPolicies-terms-conditions.svg">

                        <span>
                            {{getMyLanguage("LegalPolicies","terms-conditions.title")}}
                        </span>
                    </a>
                </div>
                
                <div class="col-6 col-lg-3">
                    <a :href="`/${flagSelected}/privacy`" class="btn-legalPolicies">
                        <img src="~@/assets/images/icons/legalPolicies-privacy.svg">

                        <span>
                            {{getMyLanguage("LegalPolicies","privacy.title")}}
                        </span>
                    </a>
                </div>

                <div class="col-6 col-lg-3">
                    <a :href="`/${flagSelected}/company-registration`" class="btn-legalPolicies">
                        <img src="https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/d5bf4f1a-0f33-4303-016f-a372248d5700/icons">
                        
                        <span>
                            {{getMyLanguage("company-registration","registration.title")}}
                        </span>
                    </a>
                </div>
                
                
                <!-- LIST POLICIES -->
                <div class="col-6 col-lg-3" v-for="(listPolicies,index) in legalPolicies" :key="index">
                    <div class="btn-legalPolicies" :class="listPolicies.slugModal" @click="openModal(listPolicies.title, listPolicies.content)">
                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${listPolicies.icon}/icons`">
                        <span>
                            {{ listPolicies.title }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal" v-if="modal.status === true">
            <div class="modal-cont">
                <div class="modal-close" @click="modal.status = false"></div>
                
                <div class="modal-scroll">
                    <h2 class="title-secondary">
                        {{ modal.titleModal }}
                    </h2>

                    <div v-html="modal.contentModal"></div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    import legalAndPolicies_ar from '@/legalPolicies/ar.json';
    import legalAndPolicies_br from '@/legalPolicies/br.json';
    import legalAndPolicies_de from '@/legalPolicies/de.json';
    import legalAndPolicies_es from '@/legalPolicies/es.json';
    import legalAndPolicies_en from '@/legalPolicies/en.json';
    import legalAndPolicies_fr from '@/legalPolicies/fr.json';
    import legalAndPolicies_it from '@/legalPolicies/it.json';
    import legalAndPolicies_pt from '@/legalPolicies/pt.json';
    import legalAndPolicies_ru from '@/legalPolicies/ru.json';

    export default {
        data () {
            return {
                legalPolicies: '',
                flagSelected: "en",

                modal: {
                    flagSelected: "en",
                    status: false,
                    titleModal: '',
                    contentModal: ''
                }
            }
        },

        async mounted(){
            this.languageLegalPolicies()
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            languageLegalPolicies(){
                const languageCode = this.$store.state.SelectLanguage.code;

                if (languageCode === 'pt') {
                    this.legalPolicies = legalAndPolicies_pt;
                } else if (languageCode === 'ar') {
                    this.legalPolicies = legalAndPolicies_ar;
                } else if (languageCode === 'br') {
                    this.legalPolicies = legalAndPolicies_br;
                } else if (languageCode === 'de') {
                    this.legalPolicies = legalAndPolicies_de;
                } else if (languageCode === 'es') {
                    this.legalPolicies = legalAndPolicies_es;
                } else if (languageCode === 'fr') {
                    this.legalPolicies = legalAndPolicies_fr;
                } else if (languageCode === 'it') {
                    this.legalPolicies = legalAndPolicies_it;
                } else if (languageCode === 'ru') {
                    this.legalPolicies = legalAndPolicies_ru;
                } else {
                    this.legalPolicies = legalAndPolicies_en;
                }
            },

            openModal(title, content){
                let titleModal = title
                let contentModal = content

                this.modal.status = true
                this.modal.titleModal = titleModal
                this.modal.contentModal = contentModal
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                    this.languageLegalPolicies();
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
   @import '@/assets/scss/_colors.scss';
   @import '@/assets/scss/_fonts.scss';
   @import '@/assets/scss/_modal.scss';
   @import '@/assets/scss/_dneElements.scss';

   .legalPolicies{
      @include paddingSection;
      background: $color-primary;
      position: relative;

      &:before{
         content: '';
         display: block;
         position: absolute;
         top: 0;
         left: 50%;
         transform: translateX(-50%);
         width: 0;
         height: 0;
         border-left: 1rem solid transparent;
         border-right: 1rem solid transparent;
         border-top: 1rem solid $color-text-tertiary;
      }

      .container-fluid{
         // max-width: 1400px;
         
         .title-secondary{
               color: $color-text-tertiary;
         }

         p{
               color: $color-secondary;
               font-family: $font-primary-semibold;
               text-align: center;
               margin-bottom: 2rem;
         }
      }

      

      .btn-legalPolicies{
         background: $color-text-tertiary;
         border-radius: 12px;
         display: flex;
         justify-content: center;
         align-items: center;
         transition: .3s;
         cursor: pointer;
         -webkit-touch-callout: none;
         -webkit-user-select: none;
         -khtml-user-select: none;
         -moz-user-select: none;
         -ms-user-select: none;
         user-select: none;


         &.complaintBook{
            img{
               width: 26px;
               height: 0;
               padding-top: 24px;
               overflow: hidden;
               background: url('~@/assets/images/icons/legalPolicies-book.svg') no-repeat;
               background-size: auto 100%;
            
               @media (max-width: 576px){
                  width: 20px;
                  padding-top: 16px;
               }
            }

         }
         

         span{
               color: $color-secondary;
               font-family: $font-primary-bold;
               border-bottom: 3px solid $color-secondary;
               padding-bottom: 3px;
         }
         
         @media (max-width: 576px){
               font-size: 12px;
               line-height: 12px;
               padding: 8px 0;
               margin: 5px 0;
               flex-direction: column;

               span{
                  font-family: $font-primary-regular;
                  border-bottom: 2px solid $color-secondary;
               }

               img{
                  height: 16px;
                  margin: 0 0 8px 0;
               }
         }

         @media (min-width: 576px){
               font-size: 14px;
               line-height: 16px;
               padding: 13px 0;
               margin: 10px 0;

               img{
                  height: 24px;
                  margin-right: 10px;
               }

               &:hover{
                  transform: scale(1.03);
               }
         }
      }


      .row{
         @media (max-width: 576px){
               margin: 0;

               .col-lg-3{
                  padding: 0 5px;
               }
         }
      }   
   }

   
   .modal-scroll{
      a{display: inline-block; color: #4aae57;}
      p > span{font-weight: 600; text-decoration: underline;}
      ol, ul{padding-left: 30px; margin: 20px 0;}
      ul > li{list-style: disc}
      img{margin-top: 30px;}
   }
</style>